<template>
  <div>
    <DashboardCreateTop
    ref="dashboardTop"
    :DashAccessLevel="accessLevel" :DashisDefault="isDefault" 
      :DashName="name"
      :dashboard_id="dashboardId"
      @dashboard-name="handleDashboardName"
      @dashboard-access-level="handleAccessLevel"
      @handleDashDelete="handleDashDelete"
      @dashboard-default="handleDefaultDashboard"
    />
    <div class="dash-page-wrapper">
      <ChartList />
      <DashboardCreate :isEditing="isEditing"  ref="resetDash" :dashboardID="dashboardId" :name="name" :accessLevel="accessLevel" :isDefault="isDefault" :charts="charts" @dashboard-id="handleDashID" />
    </div>
    <DeleteDashboard ref="deleteDashboard" :dashboard_id="dashboardId" @dashboard-delete="handleDashDeleteCreate" />
  </div>
  </template>

<script>
import DashboardCreateTop from "@/components/layout/DashboardNew/DashboardCreateTop.vue";
import ChartList from "@/components/layout/DashboardNew/ChartList.vue";
import DashboardCreate from "@/components/layout/DashboardNew/DashboardCreate.vue";
import DeleteDashboard from "@/components/layout/DashboardNew/DeleteDashboard.vue";
import api from "../../axiosInterceptor";
import { eventBus } from "../../eventBus";
export default {
  components: { ChartList, DashboardCreateTop, DashboardCreate, DeleteDashboard },
  name: "Edit",
  data() {
    return {
      name: "untitled",
      accessLevel: "private",
      isDefault: false,
      dashboardId: '', // Assuming dashboardId is available or can be fetched
      charts: [], // Array to hold the charts data
      isEditing: true
    };
  },
  methods: {
    handleDashboardName(name) {
      this.name = name;
    },
    handleDashID(dashboardData){
      this.dashboardId = dashboardData.ID;
      this.name = dashboardData.Name;
    },
    handleDashDelete(){
      this.$refs.deleteDashboard.dashboardDelete();
    },
    handleDashDeleteCreate(){
      this.$refs.resetDash.resetDashboardState();
      this.$refs.dashboardTop.resetDashboardName();
  const tenantId = this.$route.path.split('/')[1]; // Get tenant ID from the first segment
  this.$router.push(`/${tenantId}/Dashboards`);
    },
    handleAccessLevel(accessLevel) {
      this.accessLevel = accessLevel;
    },
    handleDefaultDashboard(isDefault) {
      this.isDefault = isDefault;
    },
    fetchDashboardFromUrl() {
      const routeName = this.$route.params.name;
      if (routeName) {
        this.name = routeName;
        this.getDashboardDetails(routeName);
      }
    },
    getDashboardDetails(name) {
      const dashboardString = JSON.stringify(name);
      api
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            (response.data.statusCode === 200 ||
              response.data.statusCode === 201)
          ) {
            this.dashboardId = response.data.data.dashboards[0].ID;
            this.accessLevel = response.data.data.dashboards[0].AccessLevel;
            this.isDefault = response.data.data.dashboards[0].isDefault;
            this.charts = response.data.data.dashboards[0].Charts; // Assigning charts data to the charts array
            const allChartsIds = response.data.data.dashboards[0].ChartsID;
            eventBus.emit('chart-IDs', allChartsIds);
          } else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          } else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
  },
  mounted() {
    this.fetchDashboardFromUrl();
  },
};
</script>