<template>
  <div class="left-menu-wrapper">
    <v-icon class="toggle-icon" :icon="isSidebarClosed ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
      " :style="toggleIconPosition" @click="toggleDrawer"></v-icon>

    <div :class="['sidebar', { close: isSidebarClosed }]">
      <div class="logo-div" v-if="!isSidebarClosed">
        <div class="img-wrap">
          <v-img aspect-ratio="16/9" :src="logo.logo" alt="logo"></v-img>
        </div>
      </div>

      <div class="logo-div2" v-if="isSidebarClosed">
        <div class="img-wrap2">
          <v-img :src="logo.logoIcon" alt="logo"></v-img>
        </div>
      </div>

      <ul class="nav-list">
        <!-- Loop through navigationItems -->
        <li v-for="(item, index) in filteredNavigationItems" :key="index" @click="toggleSubmenu(index)"
          :class="{ show: item.isVisible }">
          <div v-if="item.children" class="icon-link" :class="{ 
            'active-item-sub': item.title === activeMenu || 
                          (item.children && item.children.some(child => child.route === $route.path)) 
          }">
            <div class="link">
              <img :src="(item.title === activeMenu || 
                         (item.children && item.children.some(child => child.route === $route.path))) 
                        ? item.activeImage 
                        : item.image" />
              <span class="link-name">{{ item.title }}</span>
              <div class="arrow-img arrow">
                <v-img src="../../../assets/Images/LeftMenu/rightarrowblack.png" alt="arrow down"></v-img>
              </div>
            </div>
          </div>
          <ul v-if="item.children" :class="{ show: item.isVisible }" class="sub-menu">
            <li v-for="(childItem, childIndex) in item.children" :key="childIndex">
              <router-link v-if="childItem.route" :to="typeof childItem.route === 'string' ? childItem.route : childItem.route" class="sub-link">
                <img :src="$route.fullPath === childItem.route ? childItem.activeImage : childItem.image" />
                <span :class="{ 'active-text-child': $route.fullPath === childItem.route }">
                  {{ childItem.title }}
                </span>
              </router-link>
              <span v-else class="sub-link">
                <img :src="childItem.image" />
                <span :class="{ 'active-text': $route.path === childItem.route }">{{ childItem.title }}</span>
              </span>
            </li>
          </ul>
          <div class="icon-link" v-else :class="{ 'active-item': item.title === activeMenu }">
            <router-link v-if="item.route" :to="item.route" class="link">
              <img :src="item.title === activeMenu ? item.activeImage : item.image
                " />
              <span class="link-name" :class="{ 'active-text': item.title === activeMenu }">{{ item.title }}</span>
            </router-link>
            <div v-else class="link" @click="handleClick(item)">
              <img :src="item.image" />
              <div class="eda-content">
                <span class="link-name">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="left-footer" v-if="!isSidebarClosed">
        <div class="footer-img">
          <v-img aspect-ratio="16/9" :src="logo.tenantName" alt="logo"></v-img>
        </div>
        <!-- <span>{{
          getSelectedTenant ? getSelectedTenant.name : selectedTenant
        }}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../../eventBus.js";
import { getTenantPrefix } from "../../../utils/tenant.js";
export default {
  name: "LeftMenu",
  data() {
    return {
      isVisible: false,
      isSidebarClosed: true,
      screenWidth: 0,
      drawer: true,
      rail: false,
      miniVariant: false,
      selectedTenant: localStorage.getItem("tenantName"),
      activeMenu: '',
      openSubmenu: null,
      logo: {
        logo: require("../../../assets/Images/LeftMenu/logo.png"),
        logoIcon: require("../../../assets/Images/LeftMenu/logoicon.png"),
        tenantName: require("../../../assets/Svg/LeftMenu/logo-full.svg"),
        clientLogo: require("../../../assets/Images/LeftMenu/Client Logo.png"),
      },
      navigationItems: [
        {
          title: "PVT Data Visualization",
          route: `/${this.tenantId}/assets`,
          image: require("../../../assets/Svg/LeftMenu/dm_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/dm_white.svg"),
        },
        {
          title: "Data Management",
          route: `/${this.tenantId}/data-management`,
          image: require("../../../assets/Svg/LeftMenu/de_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/de_white.svg"),
        },
        // {
        //   title: "data QA-QC",
        //   route: "",
        //   image: require("../../../assets/Svg/LeftMenu/data-QA-QC_black.svg"),
        // },
        {
          title: "Studio",
          route: `/${this.tenantId}/Fluidsdata-studio`,
          image: require("../../../assets/Svg/LeftMenu/studio_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/studio_white.svg"),
        },
        // {
        //   title: "visualization & dashboards",
        //   route: "/Visualization-&-Dashboards",
        //   image: require("../../../assets/Svg/LeftMenu/dashboard_black.svg"),
        //   activeImage: require("../../../assets/Svg/LeftMenu/dashboard_white.svg"),
        // },
        {
          title: "Dashboards",
          route: `/${this.tenantId}/Dashboards`,
          image: require("../../../assets/Svg/LeftMenu/dashboard_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/dashboard_white.svg"),
        },
        {
          title: "Exploratory Data Analysis",
          image: require("../../../assets/Svg/LeftMenu/eda_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/eda_black.svg"),
          isVisible: false,
          children: [
            {
              title: "Inventory Analysis",
              route: `/${this.tenantId}/exploratory-data-analysis/inventory-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "2D Histogram/KDE Analysis",
              route: `/${this.tenantId}/exploratory-data-analysis/twodhistogram-kde`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Statistical Analysis",
              route: `/${this.tenantId}/exploratory-data-analysis/statistical-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Correlation Analysis",
              route: `/${this.tenantId}/exploratory-data-analysis/correlation-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Heatmap Analysis",
              route: `/${this.tenantId}/exploratory-data-analysis/heatmap-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "3D Plot",
              route: `/${this.tenantId}/exploratory-data-analysis/3d-plot-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Bubble Plot",
              route: `/${this.tenantId}/exploratory-data-analysis/bubble-plot-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
           
          ],
        },
      //   {
      //   title: "Security",
      //   image: require("../../../assets/Svg/LeftMenu/security-icon.svg"),
      //   activeImage: require("../../../assets/Svg/LeftMenu/security-icon.svg"),
      //   children: [
      //     {
      //       title: "User Management",
      //       image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       activeImage: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       route:
      //         `/${this.tenantId}/users`,
      //     },
      //     {
      //       title: "Role Management",
      //       image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       activeImage: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       route:
      //         `/${this.tenantId}/roles`,
      //     },
      //   ],
      // },
        // {
        //   title: "Property Predictions",
        //   image: require("../../../assets/Svg/LeftMenu/property_prediction_black.svg"),
        //   activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //   isVisible: false,
        //   children: [
        //     {
        //       title: "AI / ML Modeling",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //       route: "/ai-ml-modelling",
        //     },
        //     {
        //       title: "Advanced QA-QC",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     },
        //     {
        //       title: "Dimensionality Reduction",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     },
        //     {
        //       title: "Clustering",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     },
        //     {
        //       title: "Supervised ML Model",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     },
        //     {
        //       title: "Fluidsdata AI Model",
        //       image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //       activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //       route:
        //         "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model",
        //     },
        //   ],
        // },
      ],
    }
  },
  props: {
    menu: {
      type: Boolean,
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(newRoute) {
        // Update navigation items when route changes
        this.updateNavigationItems();
        this.handleRouteChange(newRoute.path);
        
        // Keep EDA menu open if we're on an EDA route
        if (newRoute.path.includes('exploratory-data-analysis')) {
          const edaIndex = this.navigationItems.findIndex(item => item.title === "Exploratory Data Analysis");
          if (edaIndex !== -1) {
            this.navigationItems[edaIndex].isVisible = true;
            this.openSubmenu = edaIndex;
          }
        }
      }
    },
    "$route.path"(newPath) {
      this.handleRouteChange(newPath);
      let activeItem = null;
      activeItem = this.navigationItems.find((item) => newPath === item.route);

      // If no main item matches, check subitems
      if (!activeItem) {
        this.navigationItems.forEach((item) => {
          if (item.children) {
          const  activeSubItem = item.children.find(
              (subItem) => newPath === subItem.route
            );
            if (activeSubItem) {
              activeItem = item;
              localStorage.setItem("activeMenu", activeSubItem.title); // Store subitem title
            }
          }
        });
      } else {
        const currentActiveMenu = this.activeMenu;
        // Save both to localStorage
        localStorage.setItem("lastActiveMenu", currentActiveMenu);
        localStorage.setItem("activeMenu", activeItem.title); // Store main item title
      }
      // this.activeMenu = localStorage.getItem('activeMenu');

      const activeMenu = localStorage.getItem("activeMenu") || "Data Management";
      const lastActiveMenu = localStorage.getItem("lastActiveMenu");
      const routePath = this.$route.path;
      if ([`/${this.tenantId}/assets`, `/${this.tenantId}/data-management`, `/${this.tenantId}/Fluidsdata-studio`, `/${this.tenantId}/Fluidsdata-studio-one`, `/${this.tenantId}/Fluidsdata-studio-two`, `/${this.tenantId}/Dashboards`, `/${this.tenantId}/exploratory-data-analysis`, `/${this.tenantId}/users`, `/${this.tenantId}/roles`].includes(routePath)) {
        if (routePath === `/${this.tenantId}/exploratory-data-analysis`) {
          this.localStorageMenuTitle = "Exploratory Data Analysis";
        }
        this.activeMenu = activeMenu;
      } else {
        if (lastActiveMenu === "PVT Data Visualization" && activeMenu === "Data Management") {
          this.localStorageMenuTitle = activeMenu;
        }
        else if (lastActiveMenu === "Data Management" && activeMenu === "PVT Data Visualization") {
          this.localStorageMenuTitle = activeMenu;
        }
        else if (lastActiveMenu === "PVT Data Visualization") {
          this.activeMenu = lastActiveMenu;
        } else if (lastActiveMenu === "Data Management") {
          this.activeMenu = lastActiveMenu;
        }
      }
    },
  },
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    toggleIconPosition() {
      return this.drawer ? "right: -12px;" : "left: 52px;";
    },
    tenantId() {
      // Only get tenantId from route or getTenantPrefix
      return this.$route.path.split("/")[1] || getTenantPrefix();
    },
    filteredNavigationItems() {
    return this.navigationItems
      .map((item) => {
        if (item.children) {
          // Filter child routes based on permissions
          const filteredChildren = item.children.filter((child) =>
            this.$store.getters["tenantId/hasPermission"](this.getPermissionKey(child.title))
          );
          
          // Only include parent if it has visible children
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
        } else {
          // Filter main menu items based on permissions
          if (this.$store.getters["tenantId/hasPermission"](this.getPermissionKey(item.title))) {
            return item;
          }
        }
        return null;
      })
      .filter(Boolean); // Remove null values
  }
  },
  methods: {
    getPermissionKey(title) {
    const permissionMap = {
      "PVT Data Visualization": "ReadPVTData",
      "Data Management": "ViewDataManagement",
      "Studio": "ViewStudio",
      "Dashboards": "ViewDashboards",
      "Exploratory Data Analysis": "ViewEDA",
      "Inventory Analysis": "ViewEDA",
      "Correlation Analysis": "ViewEDA",
      "Statistical Analysis": "ViewEDA",
      "2D Histogram/KDE Analysis": "ViewEDA",
      "Heatmap Analysis": "ViewEDA",
      "Bubble Plot": "ViewEDA",
      "3D Plot": "ViewEDA",
      "Security": "ManageRoles",
      "User Management": "ManageUsers",
      "Role Management": "ManageRoles"
    };

    return permissionMap[title] || null;
  },
    updateNavigationItems() {
      // Get current tenantId from route
      const currentTenantId = this.tenantId;
      this.navigationItems = [
        {
          title: "PVT Data Visualization",
          route: `/${currentTenantId}/assets`,
          image: require("../../../assets/Svg/LeftMenu/dm_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/dm_white.svg"),
        },
        {
          title: "Data Management",
          route: `/${currentTenantId}/data-management`,
          image: require("../../../assets/Svg/LeftMenu/de_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/de_white.svg"),
        },
        {
          title: "Studio",
          route: `/${currentTenantId}/Fluidsdata-studio`,
          image: require("../../../assets/Svg/LeftMenu/studio_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/studio_white.svg"),
        },
        {
          title: "Dashboards",
          route: `/${currentTenantId}/Dashboards`,
          image: require("../../../assets/Svg/LeftMenu/dashboard_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/dashboard_white.svg"),
        },
        {
          title: "Exploratory Data Analysis",
          image: require("../../../assets/Svg/LeftMenu/eda_black.svg"),
          activeImage: require("../../../assets/Svg/LeftMenu/eda_black.svg"),
          isVisible: false,
          children: [
            {
              title: "Inventory Analysis",
              route: `/${currentTenantId}/exploratory-data-analysis/inventory-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "2D Histogram/KDE Analysis",
              route: `/${currentTenantId}/exploratory-data-analysis/twodhistogram-kde`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Statistical Analysis",
              route: `/${currentTenantId}/exploratory-data-analysis/statistical-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Correlation Analysis",
              route: `/${currentTenantId}/exploratory-data-analysis/correlation-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Heatmap Analysis",
              route: `/${currentTenantId}/exploratory-data-analysis/heatmap-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "3D Plot",
              route: `/${currentTenantId}/exploratory-data-analysis/3d-plot-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
            {
              title: "Bubble Plot",
              route: `/${currentTenantId}/exploratory-data-analysis/bubble-plot-analysis`,
              image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
              activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
            },
          ],
        },
      //   {
      //   title: "Security",
      //   image: require("../../../assets/Svg/LeftMenu/security-icon.svg"),
      //   activeImage: require("../../../assets/Svg/LeftMenu/security-icon.svg"),
      //   children: [
      //     {
      //       title: "User Management",
      //       image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
      //       route:
      //       `/${this.tenantId}/users`,
      //     },
      //     {
      //       title: "Role Management",
      //       image: require("../../../assets/Svg/LeftMenu/bullet-black.svg"),
      //       activeImage: require("../../../assets/Svg/LeftMenu/bullet-active.svg"),
      //       route:
      //         `/${this.tenantId}/roles`,
      //     },
      //   ],
      // },
      ];
    },
    handleClick(item) {
      if (item.title === "Exploratory Data Analysis") {
        const index = this.navigationItems.findIndex(navItem => navItem.title === "Exploratory Data Analysis");
        if (index !== -1) {
          this.toggleSubmenu(index);
        }
        return;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawerStateChanged", this.drawer);
      this.isSidebarClosed = !this.isSidebarClosed;
      this.$emit("drawerHold", this.drawer);

      // Emit event to trigger chart resize
      eventBus.emit("resizeChart");
    },
    toggleSubmenu(index) {
      const item = this.navigationItems[index];
      
      // If this is the EDA menu and it has children
      if (item.title === "Exploratory Data Analysis" && item.children) {
        // Don't toggle closed if it's already open and we're clicking a submenu
        if (this.openSubmenu === index && !this.$route.path.includes('exploratory-data-analysis')) {
          item.isVisible = !item.isVisible;
          this.openSubmenu = item.isVisible ? index : null;
        } else {
          // Keep it open
          item.isVisible = true;
          this.openSubmenu = index;
        }
      } else {
        // For other menus
        this.navigationItems.forEach((menuItem, i) => {
          if (i === index) {
            menuItem.isVisible = true;
            this.openSubmenu = index;
          } else if (!menuItem.children || menuItem.title !== "Exploratory Data Analysis") {
            // Don't close EDA menu if it's open
            menuItem.isVisible = false;
          }
        });
      }
    },
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 768) {
        this.drawer = false;
        this.isSidebarClosed = true;
      } else if (this.screenWidth > 768) {
        if (this.menu) {
          this.drawer = true;
          this.isSidebarClosed = false;
        } else {
          this.drawer = true;
          this.isSidebarClosed = false;
        }
        this.toggleDrawer()
      }
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.toggleDrawer();
      }
    },
    handleRouteChange(newPath) {
      // Update navigation items with current tenantId
      this.updateNavigationItems();
      
      let activeItem = null;
      let activeSubItem = null;
      
      // Check if the current path includes exploratory-data-analysis
      const isEDAPath = newPath.includes('exploratory-data-analysis');
      // First check main items
      activeItem = this.navigationItems.find((item) => newPath === item.route);

      // If no main item matches, check subitems
      if (!activeItem) {
        this.navigationItems.forEach((item, index) => {
          if (item.children) {
            const foundSubItem = item.children.find(
              (subItem) => newPath === subItem.route || 
                          (isEDAPath && subItem.route.includes(newPath.split('?')[0]))
            );
            if (foundSubItem) {
              activeItem = item;
              activeSubItem = foundSubItem;
              // Keep the submenu open when a child is selected
              item.isVisible = true;
              this.openSubmenu = index;
              this.activeMenu = item.title;
              localStorage.setItem("activeMenu", item.title);
            }
          }
        });
      } else {
        const currentActiveMenu = this.activeMenu;
        localStorage.setItem("lastActiveMenu", currentActiveMenu);
        localStorage.setItem("activeMenu", activeItem.title);
        this.activeMenu = activeItem.title;
      }

      // Ensure EDA menu stays open when on EDA routes
      if (isEDAPath) {
        const edaIndex = this.navigationItems.findIndex(item => item.title === "Exploratory Data Analysis");
        if (edaIndex !== -1) {
          this.navigationItems[edaIndex].isVisible = true;
          this.openSubmenu = edaIndex;
        }
      }
      
      const activeMenu = localStorage.getItem("activeMenu") || "Data Management";
      const lastActiveMenu = localStorage.getItem("lastActiveMenu");
      const routePath = this.$route.path;
      if ([`/${this.tenantId}/assets`, `/${this.tenantId}/data-management`, `/${this.tenantId}/Fluidsdata-studio`, `/${this.tenantId}/Fluidsdata-studio-one`, `/${this.tenantId}/Fluidsdata-studio-two`, `/${this.tenantId}/Dashboards`, `/${this.tenantId}/exploratory-data-analysis`, `/${this.tenantId}/users`, `/${this.tenantId}/roles`].includes(routePath)) {
        if (routePath === `/${this.tenantId}/exploratory-data-analysis`) {
          this.localStorageMenuTitle = "Exploratory Data Analysis";
        }
        this.activeMenu = activeMenu;
      } else {
        if (lastActiveMenu === "PVT Data Visualization" && activeMenu === "Data Management") {
          this.localStorageMenuTitle = activeMenu;
        }
        else if (lastActiveMenu === "Data Management" && activeMenu === "PVT Data Visualization") {
          this.localStorageMenuTitle = activeMenu;
        }
        else if (lastActiveMenu === "PVT Data Visualization") {
          this.activeMenu = lastActiveMenu;
        } else if (lastActiveMenu === "Data Management") {
          this.activeMenu = lastActiveMenu;
        }
      }

      // If we found a subitem, make sure its parent menu is visible
      if (activeSubItem && activeItem) {
        activeItem.isVisible = true;
      }
    },
  },
  mounted() {
    this.handleRouteChange(this.$route.path);
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", this.handleKeydown);

    // Check if current route is EDA-related and open submenu accordingly
    const currentPath = this.$route.path;
    if (currentPath.includes('exploratory-data-analysis')) {
      const edaIndex = this.navigationItems.findIndex(item => item.title === "Exploratory Data Analysis");
      if (edaIndex !== -1) {
        this.navigationItems[edaIndex].isVisible = true;
        this.openSubmenu = edaIndex;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>
