<template>
    <div>
      <div v-if="(!data || data.length === 0) && loading">
        <SingleChartLoader></SingleChartLoader>
      </div>
      <div v-if="(!data || data.length === 0) && !loading" class="text-center py-5">
        <h4>Chart data is not available.</h4>
      </div>
      <div v-else-if="data && data.length > 0 && yDataAvailable === true" class="chart-column liberated-gas-chart-column">
        <div class="modal-tab-div">
            <div class="tab-div">
                <p>Y Axis Scale:</p>
                <v-btn-toggle
                    v-model="isYLog"
                    class="axis-toggle setHeight"
                    mandatory
                    @update:modelValue="handleYScaleToggleChange"
                  >
                  <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
            </div>
          </div>
        <div v-if="isMounted" class="main-chart">
          <div class="chart-name-top">
          <h6>{{chartTitle}}</h6>
          </div>
          <div :ref="chartId"></div>
          <div v-if="chartData && chartData.length > 0">
          </div>
        </div>
        <v-dialog v-model="modal" class="multi-chart-preview-div dashboard-chart-preview composition">
          <v-card class="position-relative studio-card">
            <v-card-text>
              <div class="popup-top">
                <div class="modal-tab-div">
            <div class="tab-div">
                <p>Y Axis Scale:</p>
                <v-btn-toggle
                    v-model="isModalYLog"
                    class="axis-toggle setHeight"
                    mandatory
                    @update:modelValue="handleModalYScaleToggleChange"
                  >
                  <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
            </div>
          </div>
                <div class="chart-name-top w-100">
                  <h6>{{chartTitle}}</h6>
                </div>
                <!-- <img :src="cross" alt="cross" @click="closeModal()" class="chart-preview-close-btn"> -->
              </div>
              <div :ref="modalChartId" class="modal-chart-container"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div v-else-if="data && data.length > 0 && yDataAvailable === false" class="text-center pt-5 pb-12">
        <h4>Chart data is not available.</h4>
      </div>
    </div>
  </template>
  
  <script>
  import Plotly from "plotly.js-dist-min";
  import SingleChartLoader from "../Loaders/SingleChartLoader.vue";
  import Expand from "../../../assets/Svg/Chart/expand-btn.svg";
  import downloadIcon from '../../../assets/Svg/Chart/csv_chart.svg';

  export default {
    name: "LiberatedGasPressureChart", 
    components: {
      SingleChartLoader,
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: true,
      },
      toggleFraction: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        tenantId: localStorage.getItem("tenantId"),
         cross: require("../../../assets/Svg/Chart/chart-cross.svg"),
        chartId: "lineChart",
        chartData: [],
        uniqueFluidComponents: [],
        modal: false,
        modalChartId: "modalLineChart",
        chartTitle: "Evolved Gas Compositions at various Pressures",
        isMounted: false,
        yDataAvailable: true,
        yAxisScale: "linear",
    isYLog: false,
    modalYAxisScale: "linear",
    isModalYLog: false,
      };
    },
    watch: {
      data(newVal, oldVal) {
        if (newVal !== oldVal && newVal.length > 0) {
          this.plotChart();
        }
      },
      yAxisScale() {
      this.plotChart();
    },
    },
    mounted() {
      this.isMounted = true;
    },
    methods: {
      handleYScaleToggleChange() {
    // Initialize default linear scale for the variable if not exists
    if (this.yAxisScale === undefined) {
      this.yAxisScale = 'linear';
    }

      this.yAxisScale = this.isYLog ? "log" : "linear";
      this.modalYAxisScale = this.yAxisScale;
    if(this.modalYAxisScale === "log") {
      this.isModalYLog = true;
    }
    else {
this.isModalYLog = false;
    }
  },
  handleModalYScaleToggleChange() {
    // Initialize default linear scale for the variable if not exists
    if (this.modalYAxisScale === undefined) {
      this.modalYAxisScale = 'linear';
    }

      this.modalYAxisScale = this.isModalYLog ? "log" : "linear";

      this.plotModalChart()
  },
      plotChart() {
        const differentiaLiberationData = this.data;
        const vaporMoleFractionsByComponent = {};
        // const vaporMassFractionsByComponent = {};
  
        differentiaLiberationData.forEach((step) => {
          const fluidComponent = step.fluidComponentReference;
          const vaporMoleFraction = step.vaporMoleFraction;
          // const vaporMassFraction = step.vapor_MassFraction;
          const stepPressure = step.stepPressure;
          if (!vaporMoleFractionsByComponent[fluidComponent]) {
            vaporMoleFractionsByComponent[fluidComponent] = [];
          }
          vaporMoleFractionsByComponent[fluidComponent].push({ pressure: stepPressure, moleFraction: vaporMoleFraction });
          // if (!vaporMassFractionsByComponent[stepPressure]) {
          //   vaporMassFractionsByComponent[stepPressure] = [];
          // }
  
          // vaporMassFractionsByComponent[stepPressure].push({ pressure: stepPressure, massFraction: vaporMassFraction });
        });
  
        // if (this.toggleFraction === true) {
          this.chartData = Object.entries(vaporMoleFractionsByComponent).map(([component, values]) => {
            const xData = values.map(entry => entry.pressure);
            const yData = values.map(entry => entry.moleFraction);
  
            if (yData.every(value => value === undefined) || yData.every(value => value === null) || !yData) {
              this.yDataAvailable = false;
            }
            else {
              this.yDataAvailable = true;
            }
  
            return {
              name: component,
              x: xData,
              y: yData,
            };
          });
        // } else if (this.toggleFraction === false) {
        //   this.chartData = Object.entries(vaporMassFractionsByComponent).map(([component, values]) => {
        //     const xData = values.map(entry => entry.pressure);
        //     const yData = values.map(entry => entry.massFraction);
  
        //     if (yData.every(value => value === undefined) || yData.every(value => value === null) || !yData) {
        //       this.yDataAvailable = false;
        //     }
        //     else {
        //       this.yDataAvailable = true;
        //     }
  
        //     return {
        //       name: component,
        //       x: xData,
        //       y: yData,
        //     };
        //   });
        // }
  
        this.$nextTick(() => {
          if (this.isMounted && this.$refs[this.chartId]) {
            this.plotChartWithData();
          }
        });
      },
  
      plotChartWithData() {
        const traces = this.chartData.map((series) => ({
          x: series.x,
          y: series.y,
          type: "scatter",
          mode: "lines+markers",
          name: series.name,
          hovertemplate: `%{y:.3f}<extra></extra>`
        }));
  
        const yAxisTitle = "Mole%";
  
        const layout = {
         
          xaxis: {
            title: {
              text: "Pressure (psia)",
              standoff: 10,
            },
          },
          yaxis: { 
            title: yAxisTitle,
            type: this.yAxisScale || "linear",
        },
          margin: {
          l: 50,
          r: 0
        },
          legend: { x: 0.95, y: 0, orientation: "v" },
        };

        const config = {
          responsive: true,
          displaylogo: false,
          displayModeBar: true,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d', "resetscale","zoom"],
          modeBarButtonsToAdd: [
              {
              name: "Expand",
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${Expand}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.openModal,
            },
            {
              name: "Download CSV",
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${downloadIcon}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.handleDownloadCSV,
            },
          ]
        };
  
        Plotly.newPlot(this.$refs[this.chartId], traces, layout, config);
      },
  
      plotModalChart() {
        const traces = this.chartData.map((series) => ({
          x: series.x,
          y: series.y,
          type: "scatter",
          mode: "lines+markers",
          name: series.name,
          hovertemplate: `%{y:.3f}<extra></extra>`
        }));
  
        const yAxisTitle = "Mole%";
  
        const layout = {
          xaxis: {
            title: {
              text: "Pressure (psia)",
              standoff: 10,
            },
          },
          yaxis: { title: yAxisTitle,
          type: this.modalYAxisScale || "linear",
           },
          margin: {
          l: 50,
          r: 0
        },
          legend: { x: 1.01, y: 0.035, orientation: "v" },
        };

        const config = {
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d', "resetscale","zoom"],
          modeBarButtonsToAdd: [
              {
              name: "Expand",
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${Expand}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.openModal,
            },
            {
              name: "Download CSV",
              icon: {
                svg: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <image href="${downloadIcon}" width="24" height="24"/>
                  </svg>
                `,
              },
              click: this.handleDownloadCSV,
            },
          ]
        };

        Plotly.Plots.resize(this.$refs[this.modalChartId])
        Plotly.newPlot(this.$refs[this.modalChartId], traces, layout, config);
      },
  
  
      openModal() {
        this.modal = true;
        this.$nextTick(() => {
          this.plotModalChart();
        });
      },
  
      closeModal() {
        this.modal = false;
      },

      handleDownloadCSV() {
      this.downloadChartCSV();
    },

    downloadChartCSV() {
      // Create CSV header
      const csvHeader = ["Pressure,Value\n"];

      // Map through chartData and format it as CSV rows
      const csvRows = this.chartData.map((series) =>
        series.x.map((x, index) => `${x},${series.y[index]}`).join("\n")
      );

      // Combine header and rows
      const csvContent = csvHeader + csvRows.join("\n");

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a link element
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "chart-data.csv";

      // Programmatically click the link to trigger download
      a.click();

      // Cleanup
      URL.revokeObjectURL(url);
    }
    },
  };
  </script>