<template>
  <div>
    <nav>
      <v-app-bar color="white" :elevation="0">
        <Workspace class="d-none" />
        <div class="navbar-content">

          <div class="navbar-text">{{ menuTitle }}</div>

          <div class="navbar-right-sec">
            <!-- <v-menu rounded offset-y>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon class="mr-1">
                  <v-avatar size="20">
                    <v-img
                      src="../../../assets/Images/Navbar/settings.png"
                    ></v-img>
                  </v-avatar>
                </v-btn>
              </template>
<v-card v-if="getTenantData && getTenantData.length > 1">
  <v-card-text>
    <v-btn rounded variant="text" @click="openModal">
      Change tenant
    </v-btn>
  </v-card-text>
</v-card>
</v-menu> -->

            <!-- <v-btn icon class="mr-1">
              <v-badge content="2">
                <v-avatar size="20">
                  <v-img
                    src="../../../assets/Images/Navbar/notification.png"
                  ></v-img>
                </v-avatar>
              </v-badge>
            </v-btn> -->

            <!-- <v-text-field
              :loading="loading"
              density="compact"
              variant="solo"
              label="Sample Search Query"
              prepend-inner-icon="mdi-magnify"
              single-line
              hide-details
              @click:append-inner="onClick"
            ></v-text-field>

            <v-btn class="setting-btn">
                <v-avatar size="24">
                  <v-img
                    src="../../../assets/Images/Navbar/settings.png"
                  ></v-img>
                </v-avatar>
            </v-btn>

            <v-btn class="setting-btn">
                <v-avatar size="24">
                  <v-img
                    src="../../../assets/Images/Navbar/notification.png"
                  ></v-img>
                </v-avatar>
            </v-btn> -->

            <!-- <v-divider class="vertical-divider"
              thickness="2"
              vertical
            ></v-divider> -->
            <v-menu rounded v-if="isAdmin">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="variant">
                  <v-avatar size="20">
                    <v-img :src="settingIcon" alt="security"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card class="user-dropdown-menu">
                <v-card-text>
                  <div class="mx-auto text-center">
                    <v-btn v-if="isAdmin" rounded variant="text" class="user-menu" @click="redirectToUsers">
                      <v-avatar size="20">
                        <v-img :src="userMode" alt="Roles"></v-img>
                      </v-avatar>
                      User Management
                    </v-btn>
                    <v-divider class="my-2"></v-divider>
                    <v-btn v-if="isAdmin" rounded variant="text" class="user-menu" @click="redirectToRoles">
                      <v-avatar size="20">
                        <v-img :src="roleMode" alt="Roles"></v-img>
                      </v-avatar>
                      Role Management
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-menu rounded>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="variant" class="user-dropdown">
                  <v-avatar width="32px" height="32px" style="height: 32px; width: 32px;">
                    <v-img :src="user?.profileImage" alt="profile"></v-img>
                  </v-avatar>
                  <h5 class="user-name">{{ userFullName }}</h5>
                  <v-avatar size="20">
                    <v-img src="../../../assets/Images/Navbar/chevron down.png" alt="arrow down"></v-img>
                  </v-avatar>
                </v-btn>
              </template>
              <v-card class="user-dropdown-menu">
                <v-card-text>
                  <div class="mx-auto text-center">
                    <v-avatar size="40">
                      <v-img :src="user?.profileImage" alt="profile"></v-img>
                    </v-avatar>
                    <h5 class="user-name">{{ userFullName }}</h5>
                    <p class="text-email">
                      {{ userEmail }}
                    </p>
                    <v-divider class="my-2"></v-divider>
                    <!-- <v-btn rounded variant="text"> Edit Account </v-btn> -->
                    <v-btn rounded variant="text" class="user-menu" @click="openModal">
                      Change tenant
                    </v-btn>
                    <v-divider class="my-2"></v-divider>
                    <v-btn rounded variant="text" class="user-menu" @click="logOut">
                      Logout
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>

            <!-- <v-avatar size="36" rounded="0" class="ml-1 filter-btn">
              <v-img src="../../../assets/Images/Navbar/grid.png"></v-img>
            </v-avatar> -->
          </div>
        </div>
        <Links />
      </v-app-bar>
    </nav>
    <v-dialog v-model="showModal" max-width="600" class="tenant-dialog">
      <v-card>
        <v-card-title class="px-0 d-flex align-center justify-between">Tenant Data
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" flat icon class="chart-preview-close-btn" size="x-small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 200px">
          <v-select v-if="getTenantData && getTenantData.length > 1" @update:modelValue="onSelectTenant"
            v-model="selectedTenant" :items="getTenantData.map((tenant) => tenant.name)"
            placeholder="Please select tenant">
          </v-select>
          <v-list v-else>
            <v-list-item> No tenant data available. </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :model-value="overlay" class="align-center logout-loader justify-center">
      <v-progress-circular color="primary" size="48" indeterminate></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="getLogoutError">
      <div class="text-center" v-html="getLogoutError"></div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Workspace from "./Workspace.vue";
import Links from "./Links.vue";
import { mapActions } from "vuex";
import { updateTenantInterceptor } from "@/axiosInterceptor";
import { getTenantPrefix } from "../../../utils/tenant.js";
export default {
  data: () => ({
    user: {
      fullName: "John Doe",
      email: "johndoe@gmail.com",
      profileImage: require("../../../assets/Images/Navbar/profile.png"),
    },
    userMode: require("../../../assets/Svg/profile.svg"),
    roleMode: require("../../../assets/Svg/role-module.svg"),
    settingIcon: require("../../../assets/Svg/setting-2.svg"),
    showModal: false,
    selectedTenant: localStorage.getItem("tenantName"),
    drawer: false,
    group: null,
    tenantId: localStorage.getItem("tenantId"),
    overlay: false,
    snackbarMessage: null,
    timeoutMessage: "",
    localStorageMenuTitle: localStorage.getItem("activeMenu") || "Data Management",
    selectedTenantId: ""
  }),
  components: {
    Workspace,
    Links,
  },
  computed: {
    ...mapGetters("auth", ["getExpirationTime"]),
    ...mapGetters("tenantId", ["getTenantData", "getSelectedTenant", "getUser"]),
    isAdmin() {
      return this.getUser && this.getUser.role === "Administrator";
    },
    userFullName() {
      if (this.getUser && this.getUser.firstName && this.getUser.lastName) {
        return `${this.getUser.firstName} ${this.getUser.lastName}`;
      }
    },
    menuTitle() {
      return this.localStorageMenuTitle;
    },
    userEmail() {
      return this.getUser?.username;
    },
    tenantId() {
      // Only get tenantId from route or getTenantPrefix
      return this.$route.path.split("/")[1] || getTenantPrefix();
    },
    getLogoutError() {
      const error = this.$store.getters["auth/getLogoutError"];
      if (error) {
        this.snackbarMessage = error;
      }
      return error;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    // localStorageMenuTitle(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.localStorageMenuTitle = localStorage.getItem("activeMenu");
    //   }
    //   else {
    //     this.localStorageMenuTitle = "PVT Data Visualization"
    //   }
    // },
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    async setTenantFromUrl() {
      const urlSegment = window.location.pathname.split("/")[1]; // Get first segment from URL
      const matchedTenant = this.getTenantData.find(tenant => tenant.ID === urlSegment);
      this.selectedTenantId = matchedTenant?.ID;
      const name = matchedTenant?.name;
      const ID = matchedTenant?.ID
      await this.$store.commit("tenantId/SET_SELECTED_TENANT", { name, ID });
      if (matchedTenant) {
        this.selectedTenant = matchedTenant.name; // Set selectedTenant to matched tenant name
      }
    },
    openModal() {
      this.showModal = true;
    },
    redirectToUsers() {
      localStorage.setItem("activeMenu", "User Management");
      this.$router.push(`/${this.selectedTenantId}/users`);
    },
    redirectToRoles() {
      localStorage.setItem("activeMenu", "Role Management");
      this.$router.push(`/${this.selectedTenantId}/roles`);
    },
    logOut() {
      this.overlay = true;
      this.timeoutMessage = ''
      this.sendLogoutRequest()
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            this.overlay = false;
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.overlay = false;
        })
    },
    async callTenants() {
      if (localStorage.getItem("authToken")) {
        await this.$store.dispatch("tenantId/fetchTenants");
        this.setTenantFromUrl();
      }
    },
    closeModal() {
      this.showModal = false;
    },
    async onSelectTenant(selectedValue) {
      const selectedTenant = this.getTenantData.find(
        (tenant) => tenant.name === selectedValue
      );

      if (selectedTenant) {
        const { name, ID } = selectedTenant;
        localStorage.removeItem('lastVisitedDashboard');
        localStorage.removeItem('savedChartData');
        localStorage.removeItem('lastFilterId');
        localStorage.setItem("tenantId", ID);
        this.selectedTenantId = ID;
        localStorage.setItem("tenantName", name);


        this.$router.push(`/${ID}/data-management`);

        this.closeModal();
      }
    },
    updateMenuTitle() {
      const activeMenu = localStorage.getItem("activeMenu") || "Data Management";
      const lastActiveMenu = localStorage.getItem("lastActiveMenu");
      const routePath = this.$route.path;
      if (["/assets", "/data-management", "/Fluidsdata-studio", "/Dashboards", "/exploratory-data-analysis", "/users", "/roles"].includes(routePath)) {

        // Extract route segments and ignore tenant ID
        const pathSegments = this.$route.path.split('/').filter(Boolean);
        const mainRoute = pathSegments.length > 1 ? `/${pathSegments[1].toLowerCase()}` : null;

        const validRoutes = [
          "/assets",
          "/data-management",
          "/fluidsdata-studio",
          "/dashboards",
          "/exploratory-data-analysis"
        ];

        if (mainRoute && validRoutes.includes(mainRoute)) {
          this.localStorageMenuTitle = activeMenu;
        } else {
          if (lastActiveMenu === "PVT Data Visualization" && activeMenu === "Data Management") {
            this.localStorageMenuTitle = activeMenu;
          }
          else if (lastActiveMenu === "Data Management" && activeMenu === "PVT Data Visualization") {
            this.localStorageMenuTitle = activeMenu;
          }
          else if (lastActiveMenu === "PVT Data Visualization") {
            this.localStorageMenuTitle = lastActiveMenu;
          }
          else if (lastActiveMenu === "Data Management") {
            this.localStorageMenuTitle = lastActiveMenu;
          }
        }
      }


      this.localStorageMenuTitle = localStorage.getItem("activeMenu") ? localStorage.getItem("activeMenu") : "PVT Data Visualization";
    }
  },

  mounted() {
    this.callTenants();
    this.menuTitleInterval = setInterval(() => {
      this.updateMenuTitle();
    }, 100);
  },
};
</script>