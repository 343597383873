import axios from "axios";
import router from "@/router";

const state = {
    tenantData: [],
    selectedTenant: null,
    tenantError: null,
    user: null,
    permissions: [], // Stores only permission names
};

const mutations = {
    SET_TENANT_DATA(state, data) {
        state.tenantData = data;
    },
    SET_SELECTED_TENANT(state, { name, ID }) {
        state.selectedTenant = { name, ID };
    },
    SET_TENANT_ERROR(state, error) {
        state.tenantError = error;
    },
    CLEAR_TENANT_ERROR(state) {
        state.tenantError = null;
    },
    SET_USER(state, user) {
        state.user = user;
    },
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions || []; 
        },
    
    LOGOUT(state) {
        state.user = null;
        state.permissions = [];
        state.selectedTenant = null;
        localStorage.clear(); // Clears all local storage items
    },
};

const actions = {
    async getUserData({ commit, dispatch }) {
        const extractTenantId = () => {
            const match = window.location.pathname.split("/").filter(Boolean);
            return match[0] || null; // First segment of the URL
        };

        const tenantId = localStorage.getItem("tenantId") || extractTenantId();
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_API_URL}public/api/admin/user-info`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        "X-TenantID": tenantId || "", // Ensures header is always set
                    },
                }
            );

            if (response.data?.statusCode === 200 || response.data?.statusCode === 201) {
                const userData = response.data.data;
                const permissionNames = userData.permissions?.map((perm) => perm.name) || [];
                commit("SET_USER", userData);
                commit("SET_PERMISSIONS", permissionNames);
            } else if (response.data?.statusCode === 401) {
                dispatch("logoutUser");
            } else {
                console.error("Unexpected API response:", response.data);
            }
        } catch (error) {
            console.error("Fetch user error:", error);
            if (error.code === "ERR_NETWORK") {
                dispatch("logoutUser");
            }
        }
    },

    async fetchTenants({ commit }) {
        commit("CLEAR_TENANT_ERROR");
        try {
            const response = await axios.get(
                `${process.env.VUE_APP_API_URL}public/api/admin/tenants`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                    timeout: 15000,
                }
            );

            if (response.data.statusCode === 200) {
                commit("SET_TENANT_DATA", response.data.data.tenants);
            }
        } catch (error) {
            console.error("Tenant fetch error:", error);
            if (error.code === "ECONNABORTED") {
                commit("SET_TENANT_ERROR", "Request timeout. Try again.");
            }
        }
    },

    logoutUser({ commit }) {
        commit("LOGOUT");
        router.push("/login");
    },
};

const getters = {
    getTenantData: (state) => state.tenantData,
    getSelectedTenant: (state) => state.selectedTenant,
    getTenantError: (state) => state.tenantError,
    getUser: (state) => state.user,
    getPermissions: (state) => state.permissions, // Returns only permission names

    hasPermission: (state) => (requiredPermission) => {
        return state.permissions.includes(requiredPermission);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
