<template>
  <tableView v-if="rolesData.length > 0" :roles="rolesData" :loading="loading" @roles-api-call="fetchroles" />
    <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon flat @click="fetchroles()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
        </div>
      </div>
</template>

<script>
import tableView from "@/components/Roles/table.vue";
import api from "@/axiosInterceptor"; // Ensure this is correctly set up

export default {
  components: { tableView },
  name: "index",
  data() {
    return {
      rolesData: [],
      isLoading: false,
      timeoutMessage: "",
    };
  },
  methods: {
    async fetchroles() {
      this.timeoutMessage = "";
      api
        .get("public/api/admin/roles")
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.rolesData = response.data.data;
          }
          else if (response && response.data && response.data.message) {
        this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching assets";
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchroles();
  },
};
</script>
