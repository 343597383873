<template>
    <!-- Overlay -->
    <div v-if="drawerPassword" class="custom-overlay" @click="closeDrawer"></div>

    <!-- Right-side drawer -->
    <v-navigation-drawer v-model="drawerPassword" location="right" temporary width="400" class="custom-drawer">
        <v-card flat>
            <!-- Header with Close Button -->
            <v-card-title class="d-flex justify-space-between align-center pa-0">
                <span class="text-h6 pa-0">Change Password</span>
                <v-btn variant="text" @click="closeDrawer">
                    <v-icon class="userClose">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pt-2">
                <v-window v-model="tab">
                    <v-window-item value="user">
                        <!-- Password Field -->
                        <label>Password</label>
                        <v-text-field v-model="password" :type="showPassword ? 'text' : 'password'" variant="solo"
                            class="custom-field" dense outlined clearable placeholder="User password"
                            autocomplete="new-password" :error-messages="passwordError" @input="validatePassword">
                            <template v-slot:append-inner>
                                <v-icon @click="showPassword = !showPassword">
                                    {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                                </v-icon>
                            </template>
                        </v-text-field>

                        <!-- Confirm Password Field -->
                        <label>Confirm Password</label>
                        <v-text-field v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                            variant="solo" class="custom-field" dense outlined clearable placeholder="Confirm password"
                            autocomplete="new-password" :error-messages="confirmPasswordError"
                            @input="validateConfirmPassword">
                            <template v-slot:append-inner>
                                <v-icon @click="showConfirmPassword = !showConfirmPassword">
                                    {{ showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </v-window-item>
                </v-window>
            </v-card-text>

            <!-- Action Buttons -->
            <div class="d-flex">
                <v-col cols="6" class="pa-0 pt-3 pr-1">
                    <v-btn block flat class="reset-btn text-none" @click="closeDrawer">
                        <span>Cancel</span>
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0 pt-3 pl-1">
                    <v-btn block flat class="save-btn filter-wrap-btn text-none" :loading="createLoading"
                        :disabled="isSaveDisabled" @click="savePassword">
                        <v-img :src="save" alt="save" width="15px" height="15px" class="mr-2"></v-img>
                        <span>Save</span>
                    </v-btn>
                </v-col>
            </div>
        </v-card>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
    </v-snackbar>
</template>

<script>
import api from "../../../axiosInterceptor";

export default {
    props: {
        modelValue: Boolean,
        editUser: Object,
    },
    data() {
        return {
            tab: "user",
            save: require("../../../assets/Images/save btn.png"),
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            passwordError: "",
            confirmPasswordError: "",
            createLoading: false,
            snackbar: {
                visible: false,
                message: "",
                color: "success",
                timeout: 3000,
            },
        };
    },
    computed: {
        drawerPassword: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        computed: {
            isSaveDisabled() {
                return (
                    !this.password ||
                    !this.confirmPassword ||
                    this.passwordError ||
                    this.confirmPasswordError
                );
            },
        },

    },
    watch: {
        password(newVal) {
            this.validatePassword();
        },
        confirmPassword(newVal) {
            this.validateConfirmPassword();
        },
    },
    methods: {
        showSnackbar(message, color = "success") {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.visible = true;
        },
        validatePassword() {
            const password = this.password;
            if (!password) {
                this.passwordError = "Password is required.";
            } else if (password.length < 8) {
                this.passwordError = "Password must be at least 8 characters.";
            } else if (!/[A-Z]/.test(password)) {
                this.passwordError = "Password must contain at least one uppercase letter.";
            } else if (!/[0-9]/.test(password)) {
                this.passwordError = "Password must contain at least one number.";
            } else if (!/[!@#$%^&*]/.test(password)) {
                this.passwordError = "Password must contain at least one special character.";
            } else {
                this.passwordError = "";
            }
            this.validateConfirmPassword(); // Ensure confirm password is revalidated
        },
        validateConfirmPassword() {
            if (!this.confirmPassword) {
                this.confirmPasswordError = "Confirm Password is required.";
            } else if (this.confirmPassword !== this.password) {
                this.confirmPasswordError = "Passwords do not match.";
            } else {
                this.confirmPasswordError = "";
            }
        },
        async savePassword() {
            this.validatePassword();
            this.validateConfirmPassword();
            if (this.passwordError || this.confirmPasswordError) {
                return;
            }

            this.createLoading = true;
            try {
                const userId = this.editUser.id;
                const response = await api.post(`/public/api/admin/password-update/${userId}`, {
                    password: this.password
                });

                if (response.data.statusCode === 200) {
                    this.showSnackbar("Password updated successfully!", "success");
                    this.$emit("user-updated");
                    this.closeDrawer();
                } else {
                    this.showSnackbar(response.data.message, "error");
                }
            } catch (error) {
                this.showSnackbar(error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.createLoading = false;
            }
        },
        closeDrawer() {
            this.drawerPassword = false;
            this.resetForm();
        },
        resetForm() {
            this.password = "";
            this.confirmPassword = "";
            this.passwordError = "";
            this.confirmPasswordError = "";
        },
    },
};
</script>

<style scoped>
.custom-overlay {
    position: fixed;
    height: calc(100% - 64px);
    top: 64px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1050;
}

.custom-drawer {
    z-index: 1100 !important;
}
</style>
