<template>
    <!-- Overlay -->
    <div v-if="drawer" class="custom-overlay" @click="closeDrawer"></div>

    <!-- Right-side drawer -->
    <v-navigation-drawer v-model="drawer" location="right" temporary width="400" class="custom-drawer">
        <v-card flat>
            <!-- Header with Close Button -->
            <v-card-title class="d-flex justify-space-between align-center pa-0">
                <span class="text-h6 pa-0">{{ isEditMode ? "Edit Role" : "Add Role" }}</span>
                <v-btn variant="text" @click="closeDrawer">
                    <v-icon class="userClose">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <!-- <div class="tab-div dash-access-toggle justify-space-between pb-4 pt-2">
                <v-btn-toggle v-model="isActive" class="axis-toggle" mandatory @update:modelValue="handleAccessToggleChange">
                    <v-btn :value="false" class="switch-btn" outlined>Active</v-btn>
                    <v-btn :value="true" class="switch-btn" outlined>Inactive</v-btn>
                </v-btn-toggle>
            </div> -->

            <v-divider></v-divider>
            <v-card-text class="pt-2">
                <v-window v-model="tab">
                    <v-window-item value="user">
                        <label>Name</label>
                        <v-text-field v-model="role.name" variant="solo" class="custom-field" dense outlined clearable placeholder="name"></v-text-field>

                        <label>Description</label>
                        <v-text-field v-model="role.description" variant="solo" class="custom-field" dense outlined clearable placeholder="Description"></v-text-field>

                        <label>Permissions</label>
                        <v-select v-model="role.permissions" :items="permissions" multiple chips clearable closable-chips variant="solo" placeholder="Permissions" item-title="name" item-value="name" class="custom-y-axis-chips custom-field"></v-select>
                    </v-window-item>
                </v-window>
            </v-card-text>

            <div class="d-flex">
                <v-col cols="6" class="pa-0 pt-3 pr-1">
                    <v-btn block flat class="reset-btn text-none" @click="closeDrawer">
                        <span>Cancel</span>
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0 pt-3 pl-1">
                    <v-btn block flat class="save-btn filter-wrap-btn text-none" :loading="createLoading" :disabled="isSaveDisabled" @click="saveUser">
                        <v-img :src="save" alt="save" width="15px" height="15px" class="mr-2"></v-img>
                        <span>{{ isEditMode ? "Update" : "Save" }}</span>
                    </v-btn>
                </v-col>
            </div>
        </v-card>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
</template>

<script>
import api from "../../axiosInterceptor";
export default {
    props: {
        modelValue: Boolean,
        editUser: Object, // User object for editing
    },
    data() {
        return {
            tab: "user",
            save: require("../../assets/Images/save btn.png"),
            role: {
                name: "",
                description: "",
                permissions: []
            },
            permissions: [],
            isActive: true,
            createLoading: false,
            snackbar: {
                visible: false,
                message: "",
                color: "success",
                timeout: 3000,
            },
        };
    },
    computed: {
        drawer: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        isEditMode() {
        return this.editUser && Object.keys(this.editUser).length > 0;
    },
    },
    watch: {
        editUser: {
        immediate: true,
        handler(newUser) {
            if (newUser && Object.keys(newUser).length > 0) {
                this.role = { ...newUser };
            } else {
                this.resetForm();
            }
        },
    },
    },
    methods: {
        showSnackbar(message, color = "success") {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.visible = true;
        },
        async fetchPermissions() {
            try {
                const response = await api.get("/public/api/admin/permissions");
                if (response.data.statusCode === 200) {
                    this.permissions = response.data.data.map(item => ({
                        name: item.name,
                    }));
                }
            } catch (error) {
                console.error("API error:", error);
            }
        },
        async saveUser() {
            this.createLoading = true;
            try {
                if (this.isEditMode) {
                    // Update user API call
                    const response = await api.put(`/public/api/admin/roles/${this.role.id}`, { Data: { Role: this.role } });
                    if (response.data.statusCode === 200) {
                        this.showSnackbar("Role updated successfully!", "success");
                        this.$emit("role-updated");
                        this.closeDrawer();
                    } else {
                        this.showSnackbar(response.data.message, "error");
                    }
                } else {
                    // Create user API call
                    const response = await api.post("/public/api/admin/roles", { Data: { Role: this.role } });
                    if (response.data.statusCode === 200) {
                        this.showSnackbar("Role created successfully!", "success");
                        this.$emit("role-updated");
                        this.closeDrawer();
                    } else {
                        this.showSnackbar(response.data.message, "error");
                    }
                }
            } catch (error) {
                this.showSnackbar(error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.createLoading = false;
            }
        },
        closeDrawer() {
            this.$emit("update:editUser", null);
            this.drawer = false;
        },
        handleAccessToggleChange() {
            this.user.accessLevel = this.isActive ? "active" : "inactive";
        },
        resetForm() {
            this.role = {
                name: "",
                description: "",
                permissions: []
            };
        },
    },
    mounted() {
        this.fetchPermissions();
    },
};
</script>
<style scoped>
.custom-overlay {
    position: fixed;
    height: calc(100% - 64px);
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1050;
}

.custom-drawer {
    z-index: 1100 !important;
}
</style>