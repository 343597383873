<template>
  <UserDrawer ref="drawerComponent" v-model="drawer" @user-added="callApi" :editUser.sync="editUser" />
  <ChangePassword ref="drawerComponentPassword" v-model="drawerPassword" :editUser.sync="editUser" />
  <v-container>
    <div class="exploration-table">
      <div class="table-top-content">
        <div class="d-flex align-center">
          <div class="title"><span class="userCount">{{ users.length }} users</span>Manage your users here</div>
        </div>
        <div>
          <div class="test-pdf-div">
            <v-btn class="pdf-btn" @click="openSideModal">
              <span><v-icon size="16">mdi-plus</v-icon> <span></span> Add User</span>
            </v-btn>

          </div>
        </div>

      </div>
      <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="serverItems"
        :items-per-page-options="pageOptions" :items-length="totalItems" :loading="loading" item-value="name"
        @update:options="handlePageChange" fixed-header :height="tableHeight">
        <template v-slot:loader>
          <v-skeleton-loader type="table-row-divider" :loading="loading" :types="{
            'table-row-divider': {
              height: 'calc(100vh - 425px)',
              rows: 5
            }
          }" style="background-color: #ffffff;">
          </v-skeleton-loader>
        </template>
        <template v-slot:headers>
          <tr>
            <th v-for="header in headers" :key="header.value" class="text-left search-dropdown-menu"
              :style="{ backgroundColor: 'transparent' }">


              <div class="d-flex align-center justify-space-between">
                <div class="sort-icon-title d-flex align-center" style="gap: 10px">
                  <!-- <div class="sort-icons">
                    <div class="img-div">
                      <img :src="sort_up" alt="sort-up" class="cursor-pointer" @click="sort(header.title, 'asc')" />
                    </div>
                    <div class="img-div">
                      <img :src="sort_down" alt="sort-down" class="cursor-pointer"
                        @click="sort(header.title, 'desc')" />
                    </div>
                  </div> -->
                  {{ header.title }}
                </div>
              </div>
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div class="user-container d-flex align-center">
                <!-- User Avatar with Microsoft Icon -->
                <div class="user-avatar">
                  <v-avatar size="40">
                    <v-img :src=userImage alt="User Avatar"></v-img>
                  </v-avatar>
                  <v-img v-if="item.loginType != 'local'" :src=companyIcon alt="Microsoft Logo" class="ms-icon" width="16" height="16" />

                </div>

                <!-- User Details -->
                <div class="user-details">
                  <div class="d-flex align-center">
                    <span class="user-name-table">{{ item.firstName }} {{ item.lastName }}</span>
                    <v-chip class="status-chip" color="#ABEFC6"
                      text-color="#067647" size="small">
                      Active
                    </v-chip>
                    <!-- <v-chip v-else class="status-chip" color="red-lighten-4" text-color="red-darken-2" size="small">
                      Inactive
                    </v-chip> -->
                  </div>
                  <span class="user-username">{{ item.username }}</span>
                </div>
              </div>
            </td>


            <td>
              <span v-if="item.role" class="clickable-cell no-bg">{{ item.role }}</span>

            </td>
            <td>
              <span v-for="tenant in item.tenants" :key="tenant.id" class="clickable-cell no-bg">
                {{ tenant.name }}
              </span>

            </td>

            <td>
              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn variant="text" class="float-right" v-bind="props">
                    <img :src="elipse" />
                  </v-btn>
                </template>
                <v-list dense class="custom-list">
                  <v-list-item @click="editUserModal(item)" class="custom-list-item">
                    <v-list-item-content class="d-flex align-center">
                      <img class="mr-2 icon-size" :src="pencil" />
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="openChangePasswordModal(item)" class="custom-list-item">
                    <v-list-item-content class="d-flex align-center">
                      <img class="mr-2 icon-size" :src="changePass" />
                      <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="deleteUser(item)" class="custom-list-item">
                    <v-list-item-content class="d-flex align-center">
                      <img class="mr-2 icon-size" :src="delete_icon" />
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="space-between">
            <v-btn icon :disabled="page === 1" @click="prevPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon :disabled="page === numberOfPages" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:no-data>
          <div class="d-flex justify-center align-center pa-4">
            No data available
          </div>
        </template>
      </v-data-table-server>
    </div>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import api from "../../../axiosInterceptor";
import axios from "axios";
import UserDrawer from "@/components/layout/Users/UserDrawer.vue";
import ChangePassword from "./ChangePassword.vue";
export default {
  name: "TableData",
  components: {
    UserDrawer,
    ChangePassword
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sort_up: require("../../../assets/Svg/Table/up.svg"),
      sort_down: require("../../../assets/Svg/Table/down.svg"),
      reset_icon: require("../../../assets/Images/reset.png"),
      close_icon: require("../../../assets/Images/close-circle.png"),
      elipse: require("../../../assets/Svg/elipse.svg"),
      pencil: require("../../../assets/Svg/Dashboard/pencil.svg"),
      delete_icon: require("../../../assets/Svg/Dashboard/delete.svg"),
      userImage: require("../../../assets/Images/Navbar/profile.png"),
      companyIcon: require("../../../assets/Svg/companyIcon.svg"),
      changePass: require("../../../assets/Svg/changePass.svg"),
      itemsPerPage: localStorage.getItem('itemsPerPage') || 20,
      headers: [
        { title: 'User', key: 'firstname', sortable: false },
        { title: 'Roles', key: 'roles', sortable: false },
        { title: 'Tenant', key: 'tenant', sortable: false },
        { title: 'Action', key: 'action', sortable: false }
      ],
      totalItems: 0,
      page: 1,
      pageOptions: [20, 50, 100, 200, 300, 400, 500],
      searchQuery: [],
      searchLoading: false,
      loadingCsv: false,
      drawer: false,
      drawerPassword: false,
      user: {
        name: '',
        email: '',
        role: '',
      },
      snackbar: {
        visible: false,
        message: "",
        color: "success",
        timeout: 3000,
      },
      editUser: ""
    };
  },
  computed: {
    tableHeight() {
      return this.showFilter ? 'calc(100vh - 490px)' : 'calc(100vh - 316px)';
    },
    serverItems() {
      return this.users; // Directly mapping props to computed property
    }
  },
  methods: {
    callApi() {
      this.$emit("user-api-call");
    },
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
    openSideModal() {
      this.$refs.drawerComponent.resetForm();
      this.editUser = null;
      this.drawer = true;
    },
    openChangePasswordModal(item) {
      this.editUser = item
      this.drawerPassword = true;
    },
    editUserModal(item) {
      this.editUser = item
      this.drawer = true;
    },
    handlePageChange({ page, itemsPerPage }) {
      this.$emit('update:pagination', { page, itemsPerPage });
      localStorage.setItem('itemsPerPage', itemsPerPage);
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    nextPage() {
      if (this.page < this.numberOfPages) {
        this.page++;
      }
    },
    async deleteUser(item) {
      const userId = item.id;

      try {
        const response = await api.delete(`${process.env.VUE_APP_API_URL}public/api/admin/users/${userId}`);

        if (response && response.data) {
          if (response.data.statusCode === 200 || response.data.statusCode === 201) {
            this.showSnackbar(response.data.message || "User deleted successfully!", "success");
            this.$emit("user-api-call");
          } else {
            this.showSnackbar(response.data.message || "Error deleting user", "error");
          }
        } else {
          this.showSnackbar("Unexpected response from server", "error");
        }
      } catch (error) {
        this.loading = false;
        this.showSnackbar(error.response?.data?.message || "Error deleting user", "error");
      }
    }

  },
  watch: {
    users: {
      handler(newVal) {
        this.totalItems = newVal.length; // Update total items count
      },
      immediate: true,
      deep: true
    }
  }
};
</script>


<style scoped>
.exploration-table {
  /* height: calc(100vh - 425px); */
  /* overflow-y: auto; */
  position: relative;
}

.table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.applied-filters {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Lato", sans-serif !important;
}

.applied-filters>span {
  margin-right: 10px;
  border-right: 1px solid #FFFFFF;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.applied-filters span:nth-child(5) {
  border-right: none !important;
}

.test-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-dropdown {
  background: white;
  width: 100%;
  border-radius: 4px;
  max-height: 200px;
}

.custom-list {
  width: auto;
  /* Adjusts to content width */
  min-width: 120px;
  /* Ensures it’s not too small */
}

.custom-list-item {
  padding: 4px 12px;
  /* Adjust spacing for compact look */
  min-width: fit-content;
  /* Prevents unnecessary expansion */
}

.icon-size {
  width: 18px;
  /* Slightly smaller icons */
  height: 18px;
}

.v-list-item-title {
  width: 100px !important;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 12px;
  /* Spacing between avatar and details */
  padding: 8px;
  border-radius: 8px;
}

.user-avatar {
  position: relative;
  width: 40px;
  height: 40px;
}

.ms-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name-table {
  font-weight: bold;
  font-size: 0.875rem;
}

.user-username {
  font-weight: 300;
  color: #6c757d;
  font-size: 0.875rem;
}

.status-chip {
  border-radius: 12px;
  font-size: 0.75rem;
  padding: 2px 8px;
}
</style>