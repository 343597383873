<template>
  <div>
    <chartLoaders v-if="showLoaders == true"></chartLoaders>
    <screen-loader v-if="showLoaders == true"></screen-loader>
    <div v-else-if="timeoutMessage != ''" class="tabs-no-test-div timeout">
      <div class="text-center">
        {{ timeoutMessage }}
        <v-icon flat @click="loadItems()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
      </div>
    </div>
    <v-card-text class="chart-table-wrap pa-0" v-else-if="serverItems.length > 0 && showLoaders == false">
      <!-- <v-select
        v-model="selectedSort"
        label="Sort Step Pressure"
        :items="sortOptions"
        @update:modelValue="onSelectSort"
      >
      </v-select> -->
      <div class="chart-wrap" v-if="hasStepPressure">
        <div v-for="(variable, index) in variables" :key="index" class="chart-column">
           <div class="modal-tab-div">
            <div class="tab-div">
                <p>Y Axis Scale:</p>
                <v-btn-toggle
                    v-model="isYLog[variable]"
                    class="axis-toggle setHeight"
                    mandatory
                    @update:modelValue="handleYScaleToggleChange(variable)"
                  >
                  <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
            </div>
          </div>
          <div class="chart-name-top">
          <h6>{{getHeaderDisplayName(variable)}}</h6>
          </div>
          <div :ref="`chart-${index}`" class="main-chart">
          </div>

          <v-dialog v-model="isPreviewOpen[index]" class="multi-chart-preview-div dashboard-chart-preview composition">
            <v-card class="position-relative studio-card">
              <v-card-text>
                <div class="popup-top">
                  <div class="modal-tab-div">
            <div class="tab-div">
                <p>Y Axis Scale:</p>
                <v-btn-toggle
                    v-model="isModalYLog[index]"
                    class="axis-toggle setHeight"
                    mandatory
                    @update:modelValue="handleModalYScaleToggleChange(index)"
                  >
                  <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
            </div>
          </div>
                  <div class="chart-name-top w-100">
                    <h6>{{getHeaderDisplayName(variable)}}</h6>
                  </div>
                  <!-- <img :src="cross" alt="cross" @click="closeModal(index)" class="chart-preview-close-btn"> -->
                </div>
                <div :ref="`modal-chart-${index}`" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="!hasStepPressure" class="text-center py-3">
        <h4>
          Charts couldn't be plotted as step pressure is not available in this
          test.
        </h4>
      </div>
      <div class="table-wrap">
        <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Oil Properties</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <v-img class="pointer" :src="documentDownload" alt="csv download"></v-img>
            <div>
              Download CSV
            </div>
          </div>
        </div>
        <v-data-table :headers="headers" :items="serverItems" :total-items="totalItems" :items-per-page="itemsPerPage"
          :loading="loading" :search="search" :item-value="name" virtual-items fixed-header max-height="calc(100vh - 380px)">
          <template v-slot:headers>
            <tr class="main-header">
              <th v-for="(header, index) in headers" :key="index" class="sticky-header">
                {{ header.name }}
                <span @click="handleHeaderClick(header.key)">
                  <span v-if="header.key === 'stepPressure'">
                    <v-icon v-if="sortOrder === 'asc'" @click.stop="sortStepPressure('desc')"
                      small>mdi-arrow-down</v-icon>
                    <v-icon v-else @click.stop="sortStepPressure('asc')" small>mdi-arrow-up</v-icon>
                  </span>
                </span>
              </th>
            </tr>
            <tr class="sub-header">
              <th v-for="(header, index) in headers" :key="index">
                {{ header.unit }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(header, index) in headers" :key="index">
                {{ displayValue(header.key, item[header.key]) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div v-else-if="
        (!serverItems || serverItems.length === 0) && showLoaders == false
      " class="tabs-no-test-div">
      <h4>No data available.</h4>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ChartLoaders from "../../Loaders/ChartLoaders.vue";
import ScreenLoader from "../../Loaders/ScreenLoader.vue";
import axios from "axios";
import { getDisplayName } from "../../../../utils/cache";
import api from "../../../../axiosInterceptor";
import downloadIcon from '../../../../assets/Svg/Chart/csv_chart.svg';
import Expand from "../../../../assets/Svg/Chart/expand-btn.svg";

export default {
  name: "CceData",
  components: {
    ChartLoaders,
    ScreenLoader,
  },
  data: () => ({
    showLoaders: true,
    itemsPerPage: 0,
    documentDownload: require("../../../../assets/Images/csv.svg"),
     cross: require("../../../../assets/Svg/Chart/chart-cross.svg"),
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    variables: ["1", "2"],
    isPreviewOpen: Array().fill(false),
    chartData: Array().fill(null),
    cachedData: {},
    testID: "",
    testName: "",
    sampleID: "",
    fluidSampleID: "",
    hasStepPressure: false,
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    // selectedSort: "High to Low",
    // sortOptions: ["High to Low", "Low to High"],
    sortOrder: "desc",
    timeoutMessage: "",
    yAxisScales: {},
    isYLog: {},
    modalYAxisScales: {},
    isModalYLog: {},
  }),
  methods: {
    // onSelectSort() {
    //   const sortOrder = this.selectedSort === "High to Low" ? 0 : 1; // Set sort order based on selection

    //   this.loadItems({ sortOrder })
    //     .then((ccetest) => {
    //       this.variables.forEach((variable, index) => {
    //         this.plotChartForVariable(variable, index, ccetest);
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error loading data:", error);
    //     });
    // },
    handleYScaleToggleChange(variable) {
    // Initialize default linear scale for the variable if not exists
    if (this.yAxisScales[variable] === undefined) {
      this.yAxisScales[variable] = 'linear';
    }

      this.yAxisScales[variable] = this.isYLog[variable] ? "log" : "linear";

    // Find the correct chart index
    const chartIndex = this.variables.indexOf(variable);

    this.modalYAxisScales[chartIndex] = this.yAxisScales[variable];
    if(this.modalYAxisScales[chartIndex] === "log") {
      this.isModalYLog[chartIndex] = true;
    }
    else {
this.isModalYLog[chartIndex] = false;
    }
    // Re-plot the chart using the full response data
    if (this.cachedDlTest) {
      this.plotChartForVariable(variable, chartIndex, this.cachedDlTest);
    }
  },
  handleModalYScaleToggleChange(index) {
    // Initialize default linear scale for the variable if not exists
    if (this.modalYAxisScales[index] === undefined) {
      this.modalYAxisScales[index] = 'linear';
    }

      this.modalYAxisScales[index] = this.isModalYLog[index] ? "log" : "linear";

      this.plotModalChart(index)
  },
    sortStepPressure(order) {
      this.sortOrder = order;
      if (order === "asc") {
        this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
      } else {
        this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
      }
    },
    downloadChartCSV(index) {
      const chartData = this.chartData[index];

      // Check if chart data exists
      if (chartData) {
        const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;

        // Prepare CSV header dynamically
        const csvHeader = [
          chartData.layout.xaxis.title.text,
          chartData.layout.yaxis.title.text,
        ];

        // Prepare CSV rows
        const csvRows = chartData.trace.x.map((xValue, i) => [
          xValue,
          chartData.trace.y[i],
        ]);

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      }
    },
    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var dlId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;
      this.timeoutMessage = "";

      try {
        let response = await api.get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/dltests/${dlId}`,
        );
        if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          this.cachedDlTest = response.data.data.dltest;
        this.serverItems =
          response.data.data.dltest && response.data.data.dltest.dlTestSteps
            ? response.data.data.dltest.dlTestSteps
            : [];
        this.totalItems = response.data.data.dltest.length;
        this.testID = response.data.data.dltest.ID;
        this.testName = response.data.data.dltest.name;
        this.sampleID = response.data.data.dltest.sampleID;
        this.fluidSampleID = response.data.data.dltest.fluidSampleID;
        this.saturationPressure = response.data.data.dltest.saturationPressure;
        this.saturationPressure_UOM =
          response.data.data.dltest.saturationPressure_UOM;
        this.testTemperature = response.data.data.dltest.testTemperature;
        this.testTemperature_UOM =
          response.data.data.dltest.testTemperature_UOM;
        this.sendDataToParent();

        // if (this.selectedSort === "High to Low") {
        //   this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
        // } else {
        //   this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
        // }

        // const sortByKeys = response.data.data.dltest.sortByOil.map(
        //   (key) => key.charAt(0).toLowerCase() + key.slice(1)
        // );

        let sortByKeys = [];

        if (response.data.data.dltest.sortByOil && response.data.data.dltest.sortByOil.length > 0) {
          sortByKeys = response.data.data.dltest.sortByOil.map(
            (key) => key.charAt(0).toLowerCase() + key.slice(1)
          );
        } else {
          // Fallback to using serverItems for headers and display names
          // Filter out keys that contain _UOM
          sortByKeys = Object.keys(this.serverItems[0] || {}).filter(
            (key) => !key.includes("_UOM")
          ).map(
            (key) => key.charAt(0).toLowerCase() + key.slice(1)
          );
        }

        // Check if serverItems contains stepPressure
        this.hasStepPressure = this.serverItems.some((item) =>
          item.hasOwnProperty("stepPressure")
        );

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );

        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        // Update variables array with extracted keys, excluding stepPressure
        this.variables = Array.from(allKeys).filter(
          (key) => key !== "stepPressure" && key !== "stepNumber"
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(allUniqueKeys.map(async (key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          const { displayName, digits } = await getDisplayName('DifferentialLiberationTest', capitalizedKey);
          displayNames.push(displayName);
          digitsArray.push(digits);
        }));

        // Update the headers array to use display names
        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index]; // Use display name instead of key
          let unit = uom ? uom[key + "_UOM"] : "-";
          // Check if unit is empty or equals "data-not-available", then set it to "-"
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits,
          };
        });

        return response.data.data.dltest;
      }
      else if (response && response.data && response.data.message) {
        this.timeoutMessage = response.data.message;
          }
      else {
        this.timeoutMessage = "Error in fetching data";
    }
      } catch (error) {
        this.timeoutMessage = "Error fetching data:", error;
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    handleDownloadCSV(chartIndex) {
      this.downloadChartCSV(chartIndex);
    },

    async plotChartForVariable(variable, chartIndex, dltest) {
      if (!dltest) {
        console.error("No data provided for plotting chart.");
        return;
      }

      // Find the digits value for the current variable
const header = this.headers.find(header => header.key === variable);
const digits = header ? header.digits : 0;

// Find the digits value for the stepPressure variable
const stepPressureHeader = this.headers.find(header => header.key === 'stepPressure');
const stepPressureDigits = stepPressureHeader ? stepPressureHeader.digits : 0;

// Filter and map the data points together
const filteredData = dltest.dlTestSteps
.filter(step => step.stepPressure !== undefined && step.stepPressure !== null && step[variable] !== undefined && step[variable] !== null)
.map(step => ({
x: step.stepPressure,
y: step[variable]
}));

      // const stepPressures = dltest.dlTestSteps.map((step) => step.stepPressure);

      const trace = {
        x: filteredData.map(data => data.x),
        y: filteredData.map(data => data.y),
        type: "scatter",
        mode: "lines+markers",
        name: variable,
      };

      const layout = {
        // title: {
        //   text: `${this.getHeaderDisplayName(variable)} vs Pressure`,
        //   font: {
        //     size: 12,
        //   },
        // },
        xaxis: { title: "Pressure (psia)" },
        yaxis: {
          title: this.getHeaderDisplayName(variable),
          type: this.yAxisScales[variable] || "linear",
        },
        margin: {
          l: 50,
          r: 0
        },
      };
      const config = {
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d", "resetscale","zoom"],
        modeBarButtonsToAdd: [
           {
            name: "Expand",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${Expand}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.openModal.bind(this, chartIndex),
          },
          {
            name: "Download CSV",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.handleDownloadCSV.bind(this, chartIndex),
          },
        ],
      };

      this.chartData[chartIndex] = {
        trace: trace,
        layout: layout,
        config: config,
      };

      const chartRef = this.$refs["chart-" + chartIndex];
      if (chartRef && chartRef.length > 0) {
        const chartElement = chartRef[0];
        Plotly.newPlot(chartElement, [trace], layout, config);
      } else {
        return;
      }
    },

    getHeaderDisplayName(variable) {
      const header = this.headers.find((header) => header.key === variable);
      if (header) {
        return header.unit !== "-"
          ? `${header.name} (${header.unit})`
          : header.name;
      }
      return variable;
    },

    displayValue(key, value) {
      const header = this.headers.find(header => header.key === key);
      const digits = header ? header.digits : 0; // Get digits from header

      if (value !== null && value !== undefined) {
        // Check if value is a string to avoid formatting
        if (typeof value === 'string') {
          return value;
        } else {
          // Format the value with the specified number of decimal places
          const numValue = Number(value);
          if (!isNaN(numValue)) {
            return digits > 0 ? numValue.toFixed(digits) : value;
          }
        }
      } else {
        return "-";
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.plotModalChart(index);
      });
    },
    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    plotModalChart(index) {
      const chartData = this.chartData[index];
      if (chartData) {
        const modalChartRef = this.$refs[`modal-chart-${index}`][0];
        Plotly.Plots.resize(modalChartRef);
        Plotly.newPlot(
          modalChartRef,
          [chartData.trace],
          chartData.layout,
          chartData.layout.yaxis.type = this.modalYAxisScales[index],
          chartData.config
        );
      }
    },
    sendDataToParent() {
      this.$emit("data-updated", {
        testID: this.testID,
        testName: this.testName,
        sampleID: this.sampleID,
        fluidSampleID: this.fluidSampleID,
        saturationPressure: this.saturationPressure,
        saturationPressure_UOM: this.saturationPressure_UOM,
        testTemperature: this.testTemperature,
        testTemperature_UOM: this.testTemperature_UOM,
      });
    },
    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `differential_liberation_oil_data.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },
  },
  watch: {
    $route(to, from) {
      // Call the loadItems method when route parameters change
      this.loadItems()
        .then((ccetest) => {
          this.variables.forEach((variable, index) => {
            this.plotChartForVariable(variable, index, ccetest);
          });
        })
        .catch((error) => {
          console.error("Error loading data:", error);
          // You may want to handle errors here
        });
    },
  },
  mounted() {
    this.loadItems().then((ccetest) => {
      this.variables.forEach((variable, index) => {
        this.isYLog[variable] = false;
        this.isModalYLog[index] = false;
        this.plotChartForVariable(variable, index, ccetest);
      });
    });
  },
};
</script>