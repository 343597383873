import axios from "axios";
import store from "./store";
import router from "@/router";
import { watch } from "vue";
const extractTenantId = () => {
    const match = window.location.pathname.split("/").filter(Boolean);
    return match[0] || null;
};

// Create axios instance
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});
watch(() => router.currentRoute.value.path, (newPath) => {
    const tenantId = extractTenantId();
    localStorage.setItem("tenantId", tenantId);
});

// Request interceptor to set headers
api.interceptors.request.use(config => {
    const token = localStorage.getItem("authToken");
    
    const tenantId = extractTenantId(); 

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (tenantId) {
        config.headers["X-TenantID"] = tenantId;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor to handle errors
api.interceptors.response.use(response => {
    if (response?.data?.statusCode === 401) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("tenantName");
        localStorage.removeItem("savedChartData");
        localStorage.removeItem("defaultFilter");
        router.push("/login");
    }
    return response;
}, error => {
    if (error.code === "ECONNABORTED") {
        return Promise.reject("Request timeout. Try again.");
    }
    if (error.code === "ERR_NETWORK") {
        // Uncomment if needed
        // localStorage.removeItem("authToken");
        // localStorage.removeItem("tenantName");
        // localStorage.removeItem("savedChartData");
        // localStorage.removeItem("lastFilterId");
        // router.push("/login");
    }
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("tenantName");
            localStorage.removeItem("savedChartData");
            localStorage.removeItem("defaultFilter");
            router.push("/login");
        } else if (error.response.status === 500) {
            store.commit('api/showSnackbar', {
                message: 'API error.',
                color: '#B71C1C',
                timeout: 7000,
            });
        }
    }
    return Promise.reject(error);
});

export default api;
