<template>
  <div class="dash-view dash-create pb-0">
    <div class="chart-wrap">
      <div v-for="(chart, index) in droppedCharts" :key="index" class="chart-column drag-drop-zone"
        @dragover.prevent="setDragging(index, true)" @dragleave.prevent="setDragging(index, false)"
        @drop.prevent="handleDrop($event, index)" draggable="true" @dragstart="startDrag($event, chart, index)"
        :class="{ 'is-dragging': draggingStates[index] }">
        <!-- <div class="dash-chart" v-if="loading[index]"> -->
        <DashboardCharts v-if="loading[index]" />
        <!-- </div> -->
        <p v-if="!chart">Drag and Drop your desired plots here</p>
        <div v-else>
          <h6 class="chart-name-top" v-if="!loading[index] && droppedCharts[index]?.Name">
            {{ droppedCharts[index].Name }}
          </h6>
          <div :ref="'chart_' + index" class="chart-main"></div>
        </div>
        <v-dialog v-model="isPreviewOpen[index]" class="multi-chart-preview-div dashboard-chart-preview composition">
          <v-card class="position-relative studio-card dashboard-card">
            <template v-if="
              chart?.ChartType === 'Scatter Plot' ||
              chart?.ChartType === 'Line Plot'
            ">
              <div class="modal-tab-div">
                <div class="tab-div justify-space-between">
                  <p>X Axis Scale</p>
                  <v-btn-toggle v-model="isModalXLog" class="axis-toggle" mandatory
                    @update:modelValue="handleModalXScaleToggleChange">
                    <v-btn :value="true" class="switch-btn" outlined>Log</v-btn>
                    <v-btn :value="false" class="switch-btn" outlined>Linear</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="tab-div justify-space-between">
                  <p>Y Axis Scale</p>
                  <v-btn-toggle v-model="isModalYLog" class="axis-toggle" mandatory
                    @update:modelValue="handleModalYScaleToggleChange">
                    <v-btn class="switch-btn" :value="true" outlined>Log</v-btn>
                    <v-btn class="switch-btn" :value="false" outlined>Linear</v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </template>
            <v-card-text>
              <div class="popup-top">
                <div class="chart-name-top w-100">
                  <h6>{{ chart?.Name }}</h6>
                </div>
                <!-- <img :src="cross" alt="cross" @click="closeModal(index)" class="chart-preview-close-btn"> -->
              </div>
              <div ref="modal_chart_" class="modal-chart-container"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
    <v-snackbar v-model="showSuccess" color="#4CAF50" timeout="5000">
      Success : {{ successMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import api from "../../../axiosInterceptor";
import { getDisplayName } from "../../../utils/cache";
import DashboardCharts from "../Loaders/DashboardCharts.vue";
import Expand from "../../../assets/Svg/Chart/expand-btn.svg";
import downloadIcon from "../../../assets/Svg/Chart/csv_chart.svg";
import { eventBus } from "../../../eventBus.js";
export default {
  name: "DashboardView",
  components: {
    DashboardCharts,
  },
  data() {
    return {
      duplicate: require("../../../assets/Svg/Dashboard/duplicate.svg"),
      cross: require("../../../assets/Svg/Chart/chart-cross.svg"),
      draggingStates: {},
      droppedCharts: Array(6).fill(null), // Max 6 charts
      previousDroppedCharts: Array(6).fill(null), 
      tableData: [],
      selectedColorGroupKeys: [],
      selectedYAxisKeys: [],
      selectedXAxisKeys: [],
      selectedZAxisKeys: [],
      updatedXColumnDisplayName: [],
      updatedYColumnDisplayName: [],
      isPreviewOpen: [],
      loading: [],
      xBins: [],
      yBins: [],
      xAxisScaleModal: "linear",
      yAxisScaleModal: "linear",
      isModalXLog: false,
      isModalYLog: false,
      dashboard_id: "",
      showAlert: false,
      showSuccess: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  props: {
    name: {
      type: String,
      default: "untitled",
    },
    accessLevel: {
      type: String,
      default: "private",
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    charts: {
      type: Array,
      default: false,
    },
    dashboardID: {
      type: String,
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  watch: {
  charts: async function (newValue) {
    this.previousDroppedCharts = [...this.droppedCharts]; // Store previous state
    this.droppedCharts = Array(6).fill(null);

    for (let index = 0; index < 6; index++) {
      if (index < newValue.length) {
        this.droppedCharts[index] = newValue[index];
        await this.fetchChartsData(newValue[index], index);
      } else {
        this.droppedCharts[index] = null;
        this.renderChart(null, index, "chart_");
      }
    }
  },
  name: function (newValue, oldValue) {
    if (newValue !== oldValue) {
      if (!this.droppedCharts.every(chart => chart === null)) {
        this.updateDashboard();
      }
    }
  },
  accessLevel: function () {
    if (!this.droppedCharts.every(chart => chart === null)) {
      this.updateDashboard();
    }
  },
  isDefault: function () {
    if (!this.droppedCharts.every(chart => chart === null)) {
      this.updateDashboard();
    }
  },
  dashboardID: function (newValue) {
    this.dashboard_id = newValue;
  },
},

methods: {
  resetDashboardState() {
    this.draggingStates = {};
    this.previousDroppedCharts = Array(6).fill(null);
    this.droppedCharts = Array(6).fill(null);
    this.tableData = [];
    this.selectedColorGroupKeys = [];
    this.selectedYAxisKeys = [];
    this.selectedXAxisKeys = [];
    this.selectedZAxisKeys = [];
    this.updatedXColumnDisplayName = [];
    this.updatedYColumnDisplayName = [];
    this.isPreviewOpen = [];
    this.loading = [];
    this.xBins = [];
    this.yBins = [];
    this.xAxisScaleModal = "linear";
    this.yAxisScaleModal = "linear";
    this.isModalXLog = false;
    this.isModalYLog = false;
    this.dashboard_id = "";
    this.showAlert = false;
    this.errorMessage = "";
    this.successMessage = "";
  },

  handleModalXScaleToggleChange() {
    this.xAxisScaleModal = this.isModalXLog ? "log" : "linear";
  },

  handleModalYScaleToggleChange() {
    this.yAxisScaleModal = this.isModalYLog ? "log" : "linear";
  },

  setDragging(index, isDragging) {
    this.draggingStates[index] = isDragging;
  },

  startDrag(event, chart, oldIndex = null) {
    event.dataTransfer.setData("chart", JSON.stringify(chart));
    event.dataTransfer.setData("oldIndex", oldIndex); // Save old position if dragging a dropped chart
  },

  async handleDrop(event, newIndex) {
  this.setDragging(newIndex, false);

  try {
    const chartData = event.dataTransfer.getData("chart");
    const oldIndex = event.dataTransfer.getData("oldIndex");
    const chart = JSON.parse(chartData);

    if (!chart || !chart.Name) return;

    this.previousDroppedCharts = JSON.parse(JSON.stringify(this.droppedCharts));

    if (oldIndex !== "null" && oldIndex !== "" && oldIndex !== null) {
      const movedChart = this.previousDroppedCharts[newIndex];

      if (movedChart && movedChart.Name) {
        this.droppedCharts[oldIndex] = null;
        this.$nextTick(() => {
          this.droppedCharts[oldIndex] = movedChart;
          this.droppedCharts[oldIndex].Name = movedChart.Name; // ✅ Name ensure karo

          this.droppedCharts[newIndex] = chart;
          this.droppedCharts[newIndex].Name = chart.Name; // ✅ Name ensure karo

          this.fetchChartsData(movedChart, oldIndex);
          this.fetchChartsData(chart, newIndex);
          this.updateDashboard(chart);
        });
      }
    } else {
      if (this.droppedCharts[newIndex]) {
        const availableIndex = this.previousDroppedCharts.findIndex(c => c === null);
        if (availableIndex !== -1) {
          this.droppedCharts[availableIndex] = this.previousDroppedCharts[newIndex];
          this.$nextTick(() => {
            this.droppedCharts[newIndex] = chart;
            this.droppedCharts[newIndex].Name = chart.Name; // ✅ Name ensure karo

            this.fetchChartsData(chart, newIndex);

            const isFirstDrop = this.droppedCharts.filter(c => c !== null).length === 1;
            if (isFirstDrop) {
              this.createDashboard(chart, newIndex);
            } else {
              this.updateDashboard(chart);
            }
          });
        } else {
          return;
        }
      } else {
        this.$nextTick(() => {
          this.droppedCharts[newIndex] = chart;
          this.droppedCharts[newIndex].Name = chart.Name; // ✅ Name ensure karo

          this.fetchChartsData(chart, newIndex);

          const isFirstDrop = this.droppedCharts.filter(c => c !== null).length === 1;
          if (isFirstDrop) {
            this.createDashboard(chart, newIndex);
          } else {
            this.updateDashboard(chart);
          }
        });
      }
    }

    eventBus.emit("chart-Get-removed", chart);
  } catch (error) {
    console.error("Error parsing chart data:", error);
  }
}
,  
    updateDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;
      this.loading = true;

      const sanitizedChartsID = Array.isArray(this.droppedCharts)
        ? this.droppedCharts.filter((chart) => chart && chart.ID).map((chart) => chart.ID)
        : [];

      const requestBody = {
        Data: {
          dashboard: {
            name: this.name,
            chartsID: sanitizedChartsID,
            AccessLevel: this.accessLevel,
            isDefault: this.isDefault,
          },
        },
      };

      api
        .put(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + dashboardId,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            if (this.dashboard_name) {
              localStorage.setItem("dashboard", this.dashboard_name)
            }
            if (this.isDefault) {
              localStorage.setItem("defaultDashboard", this.dashboard_name);
            }
            const getDashboard = localStorage.getItem("dashboard");
            const getDefault = localStorage.getItem("defaultDashboard");
            if (getDashboard === getDefault && this.isDefault === false) {
              localStorage.removeItem("defaultDashboard")
            }
            this.loading = false;
            this.successMessage = "Dashboard updated successfully.";
            this.showSuccess = true;
          }
          else if (response && response.data && response.data.message) {
            console.error(response.data.message);
            this.errorMessage = response.data.message;
            this.showAlert = true;
          }
          else {
            console.error("Error while updating dashboard");
            this.errorMessage = "Error while updating dashboard";
            this.showAlert = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = "Error while updating dashboard";
          this.showAlert = true;
        })
        .finally(() => {
          this.hideExpand = false;
        });
    },
    createDashboard(chart, index) {
      const tenantId = localStorage.getItem("tenantId");

      const requestBody = {
        Data: {
          dashboard: {
            name: this.name,
            chartsID: [chart.ID],
            AccessLevel: this.accessLevel,
            isDefault: this.isDefault,
          },
        },
      };

      api
        .post(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards",
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            (response.data.statusCode === 200 ||
              response.data.statusCode === 201)
          ) {
            this.dashboard_id = response.data.data.ID;
            const dashboardData = response.data.data;
            this.$emit("dashboard-id", dashboardData);
            if (this.name) {
              localStorage.setItem("dashboard", this.name)
            }
            if (this.isDefault) {
              localStorage.setItem("defaultDashboard", this.name);
            }
            this.successMessage = "Dashboard created successfully.";
            this.showSuccess = true;
          } else if (response && response.data && response.data.message) {
            this.errorMessage = response.data.message;
            this.showAlert = true;
          } else {
            this.errorMessage = "Error while creating dashboard";
            this.showAlert = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error;
          this.showAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // plotChart(index) {
    //   const container = this.$refs.chart_[index];
    //   if (!container) return;

    //   const trace = {
    //     x: [1, 2, 3, 4, 5],
    //     y: [Math.random() * 10, 8, 6, 5, Math.random() * 10],
    //     mode: "lines+markers",
    //     name: this.droppedCharts[index],
    //   };

    //   const layout = { title: this.droppedCharts[index] };
    //   Plotly.newPlot(container, [trace], layout);
    // },
    async fetchChartsData(chart, index) {
      if (typeof this.loading !== 'object') {
        this.loading = {};
      }
      this.loading[index] = true;
      const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

      if (
        response &&
        response.data &&
        (response.data.statusCode === 200 || response.data.statusCode === 201)
      ) {
        const rows = response.data.data.TableData.rows;
        if (rows) {
          this.tableData[index] = rows.map((row) => {
            Object.keys(row).forEach((key) => {
              if (key + "_decimal" in row && row[key + "_decimal"] !== null) {
                row[key] = parseFloat(row[key]).toFixed(row[key + "_decimal"]);
              }
            });
            // this.xAxisCaption[index] = row.caption
            // this.yAxisCaption[index] = row.caption
            return row;
          });
          const xAxisColumn = chart.XAxisColumn;
          const capitalizedXAxisColumn =
            xAxisColumn.charAt(0).toUpperCase() + xAxisColumn.slice(1);
          var xColumnDisplayName = await getDisplayName(
            chart.TableType,
            capitalizedXAxisColumn
          );
          this.updatedXColumnDisplayName[index] =
            xColumnDisplayName.displayName;

          const yAxisColumns = chart.YAxisColumn
            ? chart.YAxisColumn.split(",")
            : [];
          if (chart.GroupColumn && chart.YAxisColumn) {
            yAxisColumns.pop();
          }

          const capitalizedYAxisColumns = yAxisColumns.map(
            (column) => column.charAt(0).toUpperCase() + column.slice(1)
          );

          var yColumnDisplayNames = await Promise.all(
            capitalizedYAxisColumns.map((column) =>
              getDisplayName(chart.TableType, column)
            )
          );

          this.updatedYColumnDisplayName[index] = yColumnDisplayNames
            .map((item) => item.displayName)
            .join(", ");
            if (typeof this.loading !== 'object') {
        this.loading = {};
      }
      this.loading[index] = false;
          this.renderChart(chart, index, "chart_");
        }
      }
    },
    renderChart(chart, index, refPrefix) {
      this.$nextTick(() => {
        const chartRef = this.$refs[refPrefix + index];
        if (!chartRef) {
          console.warn(`Chart reference for ${refPrefix + index} is undefined.`);
          return;
        }

        const chartDiv = Array.isArray(chartRef) ? chartRef[0] : chartRef;
        if (!chartDiv) return;

        const traces = this.generateTraces(chart, index);
        const layout = this.generateLayout(chart, index);
        const config = this.generateConfig(chart, index);
        if(chart.ChartType === "Scatter Plot" || chart.ChartType === "Line Plot") {
        layout.xaxis.type = chart?.XAxisScale || "linear";
        layout.yaxis.type = chart?.YAxisScale || "linear";
        }
        Plotly.newPlot(chartDiv, traces, layout, config);
      });
    },
    renderModalChart(chart, index) {
      this.$nextTick(() => {
        const chartRef = this.$refs.modal_chart_[index];
        const chartDiv = chartRef ? chartRef : null;
        if (!chartDiv) {
          return;
        }

        const traces = this.generateTraces(chart, index);
        const layout = this.generateLayout(chart, index);
        const config = this.generateConfig(chart, index);

        this.$nextTick(() => {
          if (
            chart?.ChartType === "Scatter Plot" ||
            chart?.ChartType === "Line Plot"
          ) {
            layout.xaxis.type = this.xAxisScaleModal;
            layout.yaxis.type = this.yAxisScaleModal;
          }
          Plotly.newPlot(chartDiv, traces, layout, config);
        });
      });
    },
    generateTraces(chart, index) {
      if (!chart) {
        console.warn(
          "generateTraces received a null or undefined chart:",
          chart
        );
        return; // Exit early if chart is invalid
      }

      // Validate required properties like ChartType
      if (!chart.ChartType) {
        console.error(
          "Chart object is missing required property 'ChartType':",
          chart
        );
        return;
      }
      const traceType = this.getTraceType(chart);
      const traceMode = this.getTraceMode(chart);
      const scatterMarkerSizes = 8;

      // const yColumns = chart.YAxisColumn.split(",");
      const yColumns = chart.YAxisColumn ? chart.YAxisColumn.split(",") : [];
      this.selectedYAxisKeys[index] = yColumns;

      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop();
        this.selectedColorGroupKeys[index] = chart.GroupColumn;
        this.selectedXAxisKeys[index] = chart.XAxisColumn;
        this.selectedZAxisKeys[index] = chart.ZAxisColumn;
        this.xBins[index] = chart.NumXBins;
        this.yBins[index] = chart.NumYBins;

        const colorGroupData = this.tableData[index].map(
          (item) => item[chart.GroupColumn]
        );
        const uniqueValues = [
          ...new Set(
            colorGroupData.filter((item) => item !== undefined && item !== null)
          ),
        ];

        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        uniqueValues.forEach((value) => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        this.tableData[index].forEach((row) => {
          const colorGroupValue = row[this.selectedColorGroupKeys[index]];

          if (uniqueValues.includes(colorGroupValue)) {
            const yAxisRowData = this.selectedYAxisKeys[index].map(
              (key) => row[key]
            );
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            const xAxisRowData = row[this.selectedXAxisKeys[index]];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            const zAxisRowData = row[this.selectedZAxisKeys[index]];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });

        return Object.keys(groupedXAxisData)
          .map((key) => {
            const xData = groupedXAxisData[key];
            let yData = groupedYAxisData[key];
            const zData = groupedZAxisData[key];
            this.markerSizes = [...zData];

            if (
              !Array.isArray(xData) ||
              !Array.isArray(yData) ||
              xData.length !== yData.length
            ) {
              return null;
            }

            yData = yData.map((item) => item[0]);

            const markerSize =
              chart.ChartType === "Scatter Plot"
                ? scatterMarkerSizes
                : this.markerSizes;

            const trace = {
              x: xData,
              y: yData,
              z: zData,
              type: traceType,
              mode: traceMode,
              name: key,
              marker: {
                size: markerSize,
              },
            };

            if (
              chart.ChartType === "Bubble" ||
              chart.ChartType === "3D Scatter"
            ) {
              const maxMarkerSize = Math.max(...this.markerSizes);
              trace.marker = {
                ...trace.marker,
                sizemode: "area",
                sizeref: (2.0 * maxMarkerSize) / (80 * 2), // Adjusted size reference for better fitting
                sizemin: 2,
              };
            }

            if (["2D Histogram"].includes(chart.ChartType)) {
              trace.autobinx = false;
              trace.autobiny = false;
              trace.xbins = {
                size: this.calculateBinSize(xData, this.xBins[index]),
              };
              trace.ybins = {
                size: this.calculateBinSize(yData, this.yBins[index]),
              };
            }

            return trace;
          })
          .filter((trace) => trace !== null);
      } else {
        const xData = this.tableData[index].map(
          (item) => item[chart.XAxisColumn]
        );
        this.xBins[index] = chart.NumXBins;
        if (chart.ChartType === "Histogram") {
          //  handling for histogram: Only x-axis is needed
          // Create the histogram trace
          const trace = {
            x: xData,
            type: traceType,
            mode: traceMode,
            autobinx: false,
            xbins: {
              size: this.calculateBinSize(xData, this.xBins[index]), // Calculate bin size for the histogram
            },
          };

          return [trace];
        }
        const yData = yColumns.map((col) =>
          this.tableData[index].map((item) => item[col])
        );
        this.yBins[index] = chart.NumYBins;
        const zData = this.tableData[index].map(
          (item) => item[chart.ZAxisColumn]
        );

        this.markerSizes = [...zData];

        const markerSize =
          chart.ChartType === "Scatter Plot"
            ? scatterMarkerSizes
            : this.markerSizes;

        return yData.map((y, i) => {
          const trace = {
            x: xData,
            y: y,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: yColumns[i],
            marker: {
              size: markerSize,
            },
          };

          if (
            chart.ChartType === "Bubble" ||
            chart.ChartType === "3D Scatter"
          ) {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: "area",
              sizeref: (2.0 * maxMarkerSize) / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (chart.ChartType === "2D Histogram") {
            trace.colorscale = [
              [0.0, "rgba(247,251,255,255)"],
              [0.25, "rgba(193,217,237,255)"],
              [0.5, "rgba(97,167,210,255)"],
              [0.75, "rgba(24,101,172,255)"],
              [1.0, "rgba(8,48,108,255)"],
            ];
            trace.colorbar = {
              title: {
                text: "Number of Samples", // Title for the color bar
                side: "right",
              },
            };
          }

          if (["2D Histogram"].includes(chart.ChartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = {
              size: this.calculateBinSize(xData, this.xBins[index]),
            };
            trace.ybins = { size: this.calculateBinSize(y, this.yBins[index]) };
          }

          return trace;
        });
      }
    },

    calculateBinSize(data, bins) {
      const numericData = data.filter(
        (value) => typeof value === "number" && !isNaN(value)
      );
      if (numericData.length === 0) return 1;
      const minValue = Math.min(...numericData);
      const maxValue = Math.max(...numericData);
      return (maxValue - minValue) / bins;
    },

    generateLayout(chart, index) {
      if (!chart) {
        console.warn(
          "generateLayout received a null or undefined chart:",
          chart
        );
        return; // Exit early if chart is invalid
      }

      // Validate required properties like ChartType
      if (!chart.ChartType) {
        console.error(
          "Chart object is missing required property 'ChartType':",
          chart
        );
        return;
      }
      // const yColumns = chart.YAxisColumn.split(",");
      const yColumns = chart.YAxisColumn ? chart.YAxisColumn.split(",") : [];
      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop(); // Remove the last Y-axis column if GroupColumn is present
      }
      return {
        // title: `${chart.Name} <br> (${chart.ChartType})`,
        xaxis: {
          title: this.updatedXColumnDisplayName[index] || chart.XAxisColumn,
        },
        yaxis: {
          title:
            chart.ChartType === "Histogram"
              ? "Number of Samples"
              : this.updatedYColumnDisplayName[index]?.replace(/, /g, "<br>") ||
              yColumns.join(", ")?.replace(/, /g, "<br>"),
          automargin: true,
        },
        margin: {
          l: 50,
          r: 0,
        },
        legend: {
          title: {
            text: this.selectedColorGroupKeys[index]
              ? this.selectedColorGroupKeys[index]
              : "",
          },
        },
      };
    },
    generateConfig(chart, index) {
      const config = {
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
      };

      if (this.isEditing) {
        config.modeBarButtonsToRemove = [
          "lasso2d", "select2d", "pan2d", "resetscale", "zoom", "toImage", "toggleSpikelines", "hoverClosestCartesian", "hoverCompareCartesian", "zoomIn", "zoomOut", "autoScale"
        ];
        config.modeBarButtonsToAdd = [
          {
            name: "Remove from Dashboard",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${this.cross}" width="24" height="24"/>
                </svg>
              `,
            },
            click: () => {
              // Call a function to remove the plot from the specified index
              this.removePlotFromIndex(index, chart);
            },
          },
        ];
      } else {
        config.modeBarButtonsToRemove = ["lasso2d", "select2d", "pan2d", "resetscale", "zoom"];
        config.modeBarButtonsToAdd = [
          {
            name: "Expand",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${Expand}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.openModal.bind(this, chart, index),
          },
          {
            name: 'Download CSV',
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${downloadIcon}" width="24" height="24"/>
                </svg>
              `,
            },
            click: this.handleDownloadCSV.bind(index, chart)
          },
          {
            name: "Remove from Dashboard",
            icon: {
              svg: `
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <image href="${this.cross}" width="24" height="24"/>
                </svg>
              `,
            },
            click: () => {
              // Emit an event to notify the parent component to close the chart
              this.removePlotFromIndex(index, chart);
            },
          },
        ];
      }

      return config;
    },
    getTraceType(chart) {
      if (!chart || !chart.ChartType) {
        console.warn("Invalid chart object passed to getTraceType:", chart);
        return null; // Return a fallback or handle gracefully
      }
      switch (chart.ChartType) {
        case "Line Plot":
          return "scatter";
        case "Bar Chart":
          return "bar";
        case "Histogram":
          return "histogram";
        case "Bubble":
          return "scatter";
        case "3D Scatter":
          return "scatter3d";
        case "Scatter Plot":
          return "scatter";
        case "2D Histogram":
          return "histogram2d";
        case "Heatmap":
          return "heatmap";
        case "Treemap":
          return "treemap";
        default:
          return "scatter";
      }
    },
    getTraceMode(chart) {
      if (chart.ChartType === "Bubble") return "markers";
      if (chart.ChartType === "Scatter Plot") return "markers";
      return undefined;
    },
    openModal(chart, index) {
      this.xAxisScaleModal = chart.XAxisScale;
      this.yAxisScaleModal = chart.YAxisScale;
      if (this.xAxisScaleModal === "log") {
        this.isModalXLog = true;
      } else {
        this.isModalXLog = false;
      }
      if (this.yAxisScaleModal === "log") {
        this.isModalYLog = true;
      } else {
        this.isModalYLog = false;
      }
      // this.isModalXLog = true;
      // this.isModalYLog = true;
      this.expandedIndex = index;
      // Ensure the array has enough indexes initialized
      if (!this.isPreviewOpen[index]) {
        this.isPreviewOpen[index] = false;
      }

      // Update the modal state
      this.isPreviewOpen[index] = true;
      this.resizeChart(index, "modal_chart_");
      this.$nextTick(() => {
        // Delay resize to ensure the modal is fully rendered
        this.renderModalChart(chart, index, "modal_chart_");
        // Adjust based on your modal's animation duration
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    resizeAllCharts() {
      this.$nextTick(() => {
        this.droppedCharts.forEach((chart, index) => {
          if (!chart) return; // Skip if chart is null or undefined
          
          const chartRef = this.$refs['chart_' + index];
          if (!chartRef) {
            return;
          }

          const chartDiv = Array.isArray(chartRef) ? chartRef[0] : chartRef;
          if (!chartDiv) return;

          Plotly.Plots.resize(chartDiv); // Resize the chart
        });
      });
    },
    resizeChart(index) {
      this.$nextTick(() => {
        // const chartRef = this.$refs[refPrefix + index];

        // if (!chartRef) {
        //   console.warn(
        //     `Chart reference for ${refPrefix + index} is undefined.`
        //   );
        //   return;
        // }

        // const chartDiv = Array.isArray(chartRef) ? chartRef[0] : chartRef;
        // if (!chartDiv) return;

        const chartRef = this.$refs.modal_chart_[index];
        const chartDiv = chartRef ? chartRef : null;
        if (!chartDiv) {
          console.warn(
            `Chart reference for modal_chart_${index} is undefined.`
          );
          return;
        }

        Plotly.Plots.resize(chartDiv); // Resize the chart
      });
    },

    handleDownloadCSV(index, chart) {
      const chartData = this.tableData[index];
      const chartName = this.droppedCharts[index].Name;
      const csvContent = this.convertToCsv(chartData, chartName);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const fileName = `${chartName}.csv`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCsv(data, chartName) {
      const csvRows = [];
      const headers = ['Sample ID', 'Asset', 'Field', 'Well', 'Sample Number'].concat(Object.keys(data[0]).filter(header => header !== 'caption' && header !== '_id' && !header.endsWith('_decimal') && header !== 'field' && header !== 'asset'&& header !== 'well'));
      csvRows.push(headers.join(","));

      for (const row of data) {
        const values = headers.map((header) => {
          if (['Asset', 'Field', 'Well', 'Sample Number'].includes(header)) {
            return row.caption.split(',')[headers.indexOf(header) - 1];
          }
          if (header === 'Sample ID') {
            return row._id;
          }
          return JSON.stringify(row[header], (key, value) => value === null ? "" : value);
        });
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    },

  removePlotFromIndex(index, chart) {
    this.droppedCharts.splice(index, 1, null);
    this.tableData.splice(index, 1, []);
    this.selectedColorGroupKeys.splice(index, 1, '');
    this.selectedYAxisKeys.splice(index, 1, []);
    this.selectedXAxisKeys.splice(index, 1, []);
    this.selectedZAxisKeys.splice(index, 1, []);
    this.updatedXColumnDisplayName.splice(index, 1, '');
    this.updatedYColumnDisplayName.splice(index, 1, '');
    this.isPreviewOpen.splice(index, 1, false);
    this.xBins.splice(index, 1, []);
    this.yBins.splice(index, 1, []);
    if (typeof this.loading !== 'object') {
        this.loading = {};
      }
    this.loading[index] = false;
    this.$emit('plot-removed', index);
    this.updateDashboard();
    eventBus.emit('chart-removed', chart);
  },
  },
  mounted() {
    eventBus.on("resizeChart", this.resizeAllCharts);
    // this.loading = new Array(this.droppedCharts.length).fill(true);
  },
};
</script>

<style scoped>
.drag-drop-zone.is-dragging {
  background-color: #f8fafc;
  border-color: #94a3b8;
}
</style>