<template>
  <div :class="wrapperClass" :style="rightContentStyle" class="my-studio-update">
    <StudioDataLeft :selectedChartType="selectedChartType" @chart-type-changed="handleChartTypeChange"
      @loading-state="handleLoading" @chart-saved="handleChartSaved" @chart-title="handleChartTitle"
      @x-axis-selected="handleXAxisSelected" @y-axis-selected="handleYAxisSelected"
      @z-axis-selected="handleZAxisSelected" @chart-saved-studio="handleSavedCharts" @update-x-bins="updateXBins"
      @update-y-bins="updateYBins" @grouped-x-axis-data=handleXColorGroupSelected
      @grouped-y-axis-data=handleYColorGroupSelected @grouped-z-axis-data=handleZColorGroupSelected
      @color-group-key=handleColorGroupKey @sample-error="handleSampleError"
      @update-right-content-style="updateRightContentStyle" @handle-chart-height="handleChartHeight" @x-axis-scale="handleXAxisScale" @y-axis-scale="handleYAxisScale" />
    <DynamicChart :chartHeight="chartHeight" :loading="loading" :chartType="selectedChartType" :xAxisData="xAxisData" :yAxisData="yAxisData"
      :zAxisData="zAxisData" :markerSizes="markerSizes" :xAxisDisplayName="xAxisDisplayName"
      :xAxisCaption="xAxisCaption" :yAxisCaption="yAxisCaption" :yAxisDisplayNames="yAxisDisplayNames"
      :zAxisDisplayName="zAxisDisplayName" :chartName="chartName" :xBins="xBins" :yBins="yBins"
      :xColorGroupData="xColorGroupData" :yColorGroupData="yColorGroupData" :zColorGroupData="zColorGroupData"
      :colorGroupKey="colorGroupKey" :sampleError="sampleError" :xAxisScale="xAxisScale" :yAxisScale="yAxisScale" />
  </div>
</template>

<script>
import StudioDataLeft from './StudioDataLeft.vue';
import DynamicChart from '../Charts/DynamicChart.vue';
import { ref } from "vue";

export default {
  name: 'StudioData',
  components: {
    StudioDataLeft,
    DynamicChart
  },
  data() {
    return {
      selectedChartType: "",
      xAxisData: ref([]),
      yAxisData: ref([]),
      zAxisData: ref([]),
      xColorGroupData: ref([]),
      yColorGroupData: ref([]),
      zColorGroupData: ref([]),
      markerSizes: ref([]),
      xAxisDisplayName: "",
      xAxisCaption: "",
      yAxisCaption: "",
      yAxisDisplayNames: ref([]),
      zAxisDisplayName: "",
      loading: false,
      chartName: "",
      xBins: null,
      yBins: null,
      colorGroupKey: "",
      sampleError: "",
      rightContentStyle: {},
      chartHeight: "",
      xAxisScale: "",
      yAxisScale: "",
    };
  },
  computed: {
    wrapperClass() {
  const pathSegments = this.$route.path.split('/').filter(Boolean); // Remove empty segments
  const mainRoute = pathSegments.length > 1 ? pathSegments[1].toLowerCase() : null;

  if (mainRoute === 'dashboards') {
    return 'dashboard-wrapper';
  } else if (mainRoute === 'fluidsdata-studio') {
    return 'studio-page-wrapper';
  } else {
    return '';
  }
}

  },
  methods: {
    handleChartHeight(inputs) {
      this.chartHeight = inputs;      
    },
    updateRightContentStyle(style) {
      this.rightContentStyle = style;
    },
    updateXBins(newVal) {
      this.xBins = newVal;
    },
    updateYBins(newVal) {
      this.yBins = newVal;
    },
    handleChartTitle(chartTitle) {
      this.chartName = chartTitle;
    },
    handleLoading(showloaders) {
      this.loading = showloaders;
    },
    handleChartSaved(chartSaved) {
      this.chartSaved = chartSaved;
      // this.$emit("chart-saved-studio", chartSaved);
      this.$emit("save-chart", chartSaved);
    },
    handleChartTypeChange(selectedType) {
      this.selectedChartType = selectedType;
    },
    handleXAxisSelected({ data, displayName, caption }) {
      this.xAxisData = data;
      this.xAxisDisplayName = displayName;
      this.xAxisCaption = caption
    },
    handleYAxisSelected({ data, displayNames, caption }) {
      this.yAxisData = data;
      this.yAxisDisplayNames = displayNames;
      this.yAxisCaption = caption
    },
    handleZAxisSelected({ data, displayName }) {
      this.zAxisData = data;
      this.zAxisDisplayName = displayName;
      this.markerSizes = [...this.zAxisData];
    },
    handleXColorGroupSelected(groupedXAxisData) {
      this.xColorGroupData = groupedXAxisData;
    },
    handleYColorGroupSelected(groupedYAxisData) {
      this.yColorGroupData = groupedYAxisData;
    },
    handleZColorGroupSelected(groupedZAxisData) {
      this.zColorGroupData = groupedZAxisData;
    },
    handleColorGroupKey(selectedColorGroupKey) {
      this.colorGroupKey = selectedColorGroupKey
    },
    handleSavedCharts(title) {
      this.$emit("save-chart", title)
    },
    handleSampleError(sampleError) {
      this.sampleError = sampleError
    },
    handleXAxisScale(xAxisScale) {
      this.xAxisScale = xAxisScale
    },
    handleYAxisScale(yAxisScale) {
      this.yAxisScale = yAxisScale
    },
  }
};
</script>