<template>
  <div>
    <div class="dash-left-side">
      <div class="chart-create">
        <span>Plot Inventory</span>
        <p>Drag drop plot from the list below into the center pane.</p>
      </div>
      <div class="chart-search">
        <img :src="search" alt="search" />
        <input type="search" placeholder="search plot" v-model="searchQuery" />
      </div>
      <DashChartListingLoader v-if="showLoader == true" />
      <div class="dash-list-public" v-else>
        <div v-for="(chart, index) in filteredCharts" :key="index" class="dash-list-items" draggable="true"
          @dragstart="startDrag($event, chart)">
          <div>
            <span class="dash-name">{{ chart.Name }}</span>
          </div>
          <img :src="hand" alt="hand" />
        </div>
        <p v-if="filteredCharts.length === 0">No plot found.</p>
      </div>

      <v-dialog v-model="showStudioDataDialog" width="90vw" height="85vh">
        <v-card class="position-relative">
          <v-card-text>
            <div class="d-flex align-center justify-space-between pa-4 pb-0">
              <v-spacer></v-spacer>
              <v-btn @click="closeModal" flat icon class="chart-preview-close-btn" size="x-small">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <StudioData class="px-4" @save-chart="handleChartSavedStudio" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="create-plot-wrap set-cursor" @click="openStudioDataDialog">
      <img :src="add" alt="add" />
      <span>Create Plot</span>
    </div>
  </div>
</template>

<script>
import DashChartListingLoader from "../Loaders/DashChartListingLoader.vue";
import api from "../../../axiosInterceptor";
import StudioData from '../FluidsDataStudioTwo/StudioData.vue';
import { eventBus } from "../../../eventBus.js";

export default {
  name: "DashboardList",
  components: {
    DashChartListingLoader,
    StudioData,
  },
  data() {
    return {
      allChartTypes: [],
      // chartTypesName: [],
      searchQuery: "",
      showLoader: true,
      dash_create: require("../../../assets/Svg/Dashboard/create-btn.svg"),
      hand: require("../../../assets/Svg/Dashboard/hand.svg"),
      search: require("../../../assets/Svg/Dashboard/search.svg"),
      add: require("../../../assets/Svg/Dashboard/add.svg"),
      showStudioDataDialog: false,
      draggedChart: null,
    };
  },
  computed: {
    filteredCharts() {
      return this.allChartTypes.filter((chart) =>
        chart.Name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    handleChartSavedStudio() {
      this.closeModal();
      this.getSavedCharts();
    },
    openStudioDataDialog() {
      this.showStudioDataDialog = true;
    },
    closeModal() {
      this.showStudioDataDialog = false;
    },
    startDrag(event, chart) {
      event.dataTransfer.setData("chart", JSON.stringify(chart));
    },
    handleDragEnd() {
      if (this.draggedChart) {
        this.allChartTypes.push(this.draggedChart);
        this.draggedChart = null;
      }
    },
    getSavedCharts() {
      this.showLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      api
        .get(process.env.VUE_APP_API_URL + "public/api/v1/charts", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            (response.data.statusCode === 200 ||
              response.data.statusCode === 201)
          ) {
            this.allChartTypes = response.data.data.charts;
            // this.chartTypesName = [
            //   ...this.allChartTypes.map((chart) => chart.Name),
            // ];
          } else if (response && response.data && response.data.message) {
            console.error(response.data.message);
          } else {
            console.error("Error in fetching charts");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
  },
  mounted() {
    this.getSavedCharts();
    eventBus.on('chart-removed', (chart) => {
      if (!this.allChartTypes.some(c => c.ID === chart.ID)) {
        this.allChartTypes.unshift(chart);
      }
    });
    eventBus.on('chart-Get-removed', (chart) => {
      this.draggedChart = chart;
      this.allChartTypes = this.allChartTypes.filter(c => c.ID !== chart.ID);
    });
    eventBus.on('chart-IDs', (allChartsIds) => {
      this.allChartTypes = this.allChartTypes.filter(c => !allChartsIds.map(id => id.toString()).includes(c.ID));
    });
    eventBus.on('chart-Get-back', (charts) => {
      console.log(charts)
      charts.forEach(chart => {
        if (!this.allChartTypes.some(c => c.ID === chart.ID)) {
        this.allChartTypes.unshift(chart);
      }
    });
    });
  },
  beforeUnmount() {
  },
};
</script>