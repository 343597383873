<template>
    <!-- Overlay -->
    <div v-if="drawer" class="custom-overlay" @click="closeDrawer"></div>

    <!-- Right-side drawer -->
    <v-navigation-drawer v-model="drawer" location="right" temporary width="400" class="custom-drawer">
        <v-card flat>
            <!-- Header with Close Button -->
            <v-card-title class="d-flex justify-space-between align-center pa-0">
                <span class="text-h6 pa-0">{{ isEditMode ? "Edit User" : "Add User" }}</span>
                <v-btn variant="text" @click="closeDrawer">
                    <v-icon class="userClose">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <!-- <div class="tab-div dash-access-toggle justify-space-between pb-4 pt-2">
                <v-btn-toggle v-model="isActive" class="axis-toggle" mandatory
                    @update:modelValue="handleAccessToggleChange">
                    <v-btn :value="false" class="switch-btn" outlined>Active</v-btn>
                    <v-btn :value="true" class="switch-btn" outlined>Inactive</v-btn>
                </v-btn-toggle>
            </div> -->

            <v-divider></v-divider>
            <v-card-text class="pt-2">
                <v-window v-model="tab">
                    <v-window-item value="user">
                        <label>First Name</label>
                        <v-text-field v-model="user.firstName" variant="solo" class="custom-field" dense outlined
                            clearable placeholder="First Name"></v-text-field>

                        <label>Last Name</label>
                        <v-text-field v-model="user.lastName" variant="solo" class="custom-field" dense outlined
                            clearable placeholder="Last Name"></v-text-field>

                        <label>Username/Email Address</label>
                        <v-text-field v-model="user.username" variant="solo" class="custom-field" dense outlined
                            placeholder="Username/Email Address" :readonly="isEditMode">
                        </v-text-field>

                        <label>Role</label>
                        <v-select v-model="user.role" :items="roles" variant="solo" placeholder="Role" item-title="name"
                            item-value="name" clearable class="custom-y-axis-chips custom-field mb-2"></v-select>

                        <label v-if="!isEditMode">Password</label>
                        <v-text-field v-if="!isEditMode" v-model="user.password" type="password" variant="solo"
                            class="custom-field" dense outlined clearable placeholder="User password"
                            autocomplete="new-password">
                        </v-text-field>


                        <label>Tenants</label>
                        <v-select v-model="user.tenantIDs" :items="tenants" multiple chips clearable closable-chips
                            variant="solo" placeholder="Tenants" item-title="name" item-value="value"
                            class="custom-y-axis-chips custom-field"></v-select>
                    </v-window-item>
                </v-window>
            </v-card-text>

            <div class="d-flex">
                <v-col cols="6" class="pa-0 pt-3 pr-1">
                    <v-btn block flat class="reset-btn text-none" @click="closeDrawer">
                        <span>Cancel</span>
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-0 pt-3 pl-1">
                    <v-btn block flat class="save-btn filter-wrap-btn text-none" :loading="createLoading"
                        :disabled="isSaveDisabled" @click="saveUser">
                        <v-img :src="save" alt="save" width="15px" height="15px" class="mr-2"></v-img>
                        <span>{{ isEditMode ? "Update" : "Save" }}</span>
                    </v-btn>
                </v-col>
            </div>
        </v-card>
    </v-navigation-drawer>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
    </v-snackbar>
</template>

<script>
import api from "../../../axiosInterceptor";
export default {
    props: {
        modelValue: Boolean,
        editUser: Object, // User object for editing
    },
    data() {
        return {
            tab: "user",
            save: require("../../../assets/Images/save btn.png"),
            user: {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                tenantIDs: [],
                role: null,
            },
            roles: [],
            tenants: [],
            isActive: true,
            createLoading: false,
            snackbar: {
                visible: false,
                message: "",
                color: "success",
                timeout: 3000,
            },
        };
    },
    computed: {
        drawer: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        isEditMode() {
            return this.editUser && Object.keys(this.editUser).length > 0;
        },
    },
    watch: {
        editUser: {
            immediate: true,
            handler(newUser) {
                if (newUser && Object.keys(newUser).length > 0) {
                    this.user = { ...newUser };
                } else {
                    this.resetForm();
                }
            },
        },
    },
    methods: {
        showSnackbar(message, color = "success") {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.visible = true;
        },
        async fetchRoles() {
            try {
                const response = await api.get("/public/api/admin/roles");
                if (response.data.statusCode === 200) {
                    this.roles = response.data.data.map(role => ({
                        name: role.name,
                        value: role.id,
                    }));
                }
            } catch (error) {
                console.error("API error:", error);
            }
        },
        async fetchTenants() {
            try {
                const response = await api.get("/public/api/admin/tenants");
                if (response.data.statusCode === 200) {
                    this.tenants = response.data.data.tenants.map(tenant => ({
                        name: tenant.name,
                        value: tenant.ID,
                    }));
                }
            } catch (error) {
                console.error("API error:", error);
            }
        },
        async saveUser() {
            this.createLoading = true;
            try {
                if (this.isEditMode) {
                    // Update user API call
                    const response = await api.put(`/public/api/admin/users/${this.user.id}`, { data: { user: this.user } });
                    if (response.data.statusCode === 200) {
                        this.showSnackbar("User updated successfully!", "success");
                        this.$emit("user-added");
                        this.closeDrawer();
                    } else {
                        this.showSnackbar(response.data.message, "error");
                    }
                } else {
                    // Create user API call
                    const response = await api.post("/public/api/admin/users", { data: { user: this.user } });
                    if (response.data.statusCode === 200) {
                        this.showSnackbar("User created successfully!", "success");
                        this.$emit("user-added");
                        this.closeDrawer();
                    } else {
                        this.showSnackbar(response.data.message, "error");
                    }
                }
            } catch (error) {
                this.showSnackbar(error.response?.data?.message || "An error occurred", "error");
            } finally {
                this.createLoading = false;
            }
        },
        closeDrawer() {
            this.$emit("update:editUser", null);
            this.drawer = false;
        },
        // handleAccessToggleChange() {
        //     this.user.accessLevel = this.isActive ? "active" : "inactive";
        // },
        resetForm() {
            this.user = {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                tenantIDs: [],
                role: null,
                accessLevel: "active",
            };
        },
    },
    mounted() {
        this.fetchRoles();
        this.fetchTenants();
    },
};
</script>
<style scoped>
.custom-overlay {
    position: fixed;
    height: calc(100% - 64px);
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1050;
}

.custom-drawer {
    z-index: 1100 !important;
}
</style>