<template>
  <div>
    <div class="dash-view">
      <div class="view-top" v-if="dashboard_name">
        <h6 class="view-name">
          {{ dashboard_name }}
        </h6>
        <div class="d-flex items-center">
          <!-- <v-img :src="duplicate" alt="duplicate" v-if="dashboardAccessLevel == 'public'"></v-img> -->
          <div class="edit-delete-dashboard ml-2">
            <div class="tab-div dash-access-toggle justify-space-between">
              <v-btn-toggle v-model="isPublic" class="axis-toggle font-overlay" mandatory
                @update:modelValue="handleDashAccessToggleChange">
                <v-btn :value="false" class="switch-btn" outlined>Private</v-btn>
                <v-btn :value="true" class="switch-btn" outlined>Public</v-btn>
              </v-btn-toggle>
            </div>
            <v-btn variant class="save-btn" v-if="
              dashboard_name &&
              (dashboardAccessLevel !== 'public' || isAdmin)
            " @click="editChart">
              <img :src="pencil" alt="edit" />
              <span style="margin-top: 2px">Edit</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="dash-view-content">
        <div v-if="showLoader">
          <ScreenLoader></ScreenLoader>
        </div>
        <ChartsDataNew ref="childRef" v-if="isDataFetched && !showLoader" :chartTypes="chartTypes"
          :isEditing="isEditing" />
        <div class="no-dashboard-div"
          v-if="(!selectedDashboard || Object.keys(selectedDashboard).length === 0) && !showLoader && !dashboard_name">
          <p>No dashboard selected.</p>
        </div>
      </div>
    </div>
    <v-dialog transition="dialog-top-transition" class="save-dashboard" width="600" v-model="showDeleteConfirmation">
      <!-- <v-alert title="Delete Dashboard" text="Are you sure you want to delete dashboard ?">
                <v-btn variant="tonal" class="text-none" @click="closeDeleteConfirmation">Cancel</v-btn>
                <v-btn color="error" class="text-none" text @click="confirmDeleteDashboard" :loading="loading">Delete</v-btn>
            </v-alert> -->
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title class="d-flex align-center justify-between">
            <v-spacer></v-spacer>
            <v-btn @click="closeDeleteConfirmation" flat icon class="chart-preview-close-btn" size="x-small">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-4 mx-5">
            <h6 class="text-center">Delete Dashboard</h6>
            <p class="text-center">
              Are you sure you want to delete dashboard?
            </p>
          </v-card-text>
          <v-card-actions class="btn-wrap">
            <v-btn class="cancel-btn" @click="isActive.value = false">
              <span>Cancel</span>
            </v-btn>
            <v-btn class="delete-btn" @click="confirmDeleteDashboard" :loading="loading"
              v-if="dashboardAccessLevel !== 'public' || isAdmin">
              <img class="mr-2" :src="deleteDashboardPopup" width="15px" height="15px" alt="delete_dashboard" />
              <span>Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>


<script>
import ChartsDataNew from "../DashboardNew/ChartsDataNew.vue";
import api from "../../../axiosInterceptor";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import { mapGetters } from "vuex";
export default {
  name: "DashboardView",
  components: {
    ChartsDataNew,
    ScreenLoader,
  },
  data() {
    return {
      duplicate: require("../../../assets/Svg/Dashboard/duplicate.svg"),
      delete: require("../../../assets/Svg/Dashboard/delete.svg"),
      deleteDashboardPopup: require("../../../assets/Svg/Chart/delete-white.svg"),
      chartTypes: [],
      isDataFetched: false,
      dashboard_name: "",
      dashboard_id: "",
      dashboardAccessLevel: "",
      dashboard: null,
      showLoader: false,
      pencil: require("../../../assets/Svg/edit-pencil.svg"),
      isEditing: false,
      isDefault: false,
      showDeleteConfirmation: false,
      loading: false,
      accessLevel: "",
      isPublic: false,
    };
  },
  props: {
    selectedDashboard: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters("tenantId", ["getUser"]),
    isAdmin() {
      return this.getUser && this.getUser.role === "Administrator";
    },
  },
  watch: {
    selectedDashboard: {
      handler(newVal) {
        if (newVal && newVal.name) {
          this.fetchDataOnChange();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleDashAccessToggleChange() {
      // Update access level based on the switch state
      this.accessLevel = this.isPublic ? "public" : "private";
      if (this.selectedDashboard) {
        // Update the dashboard in the backend
        const requestBody = {
          Data: {
            dashboard: {
              name: this.dashboard_name,
              AccessLevel: this.accessLevel,
            },
          },
        };
        api
          .put(
            process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + this.dashboard_id,
            requestBody,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "X-TenantID": this.getTenantId,
              },
            }
          )
          .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
              this.$emit('dashboard-delete');
            } else {
              console.error("Failed to update dashboard access level.");
            }
          })
          .catch(error => {
            console.error("Error updating dashboard access level:", error);
          });
      }
    },
    editChart() {
      this.$router.push({ name: "DashboardEdit", params: { name: this.dashboard_name } });
    },
    fetchDataOnChange() {
      this.showLoader = true;
      //   this.newDashboard = localStorage.getItem("dashboard");
      this.newDashboard = this.selectedDashboard?.name;
      const dashboardString = JSON.stringify(this.newDashboard);
      api
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            (response.data.statusCode === 200 ||
              response.data.statusCode === 201)
          ) {
            // this.showChartInput = false;
            // this.showSaveBtn = false;
            // this.showTitle = true;
            // this.showCharts = true;
            // this.editName = false;
            // this.showEditBtn = true;
            // this.showCancelBtn = false;
            // this.showSelectboxes = false;
            // localStorage.removeItem("editDashboard")
            this.dashboard = response.data.data.dashboards[0].name;
            this.dashboard_name = response.data.data.dashboards[0].name;
            this.dashboard_id = response.data.data.dashboards[0].ID;
            this.dashboardAccessLevel =
              response.data.data.dashboards[0].AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = response.data.data.dashboards[0].Charts;
            // this.chartTypesName = [
            //   ...this.allChartTypes.map((chart) => chart.Name),
            //   "+ Create Plot"
            // ];
            // this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            // this.updateSelectedChartId = [...this.selectedChartIds];
            // this.chartSelections = Array(this.chartTypes.length).fill(null);
            // this.chartTypes.forEach((chart, index) => {
            //   this.chartSelections[index] = chart?.Name;
            // });
            this.isDataFetched = true;
          } else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          } else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    fetchDefaultData() {
      this.showLoader = true;
      this.dashboardName = localStorage.getItem("defaultDashboard");
      const dashboardString = JSON.stringify(this.dashboardName);
      api
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = dashboardData.Charts;
            // this.chartTypesName = [
            //   ...this.allChartTypes.map((chart) => chart.Name),
            //   "+ Create Plot"
            // ];
            // this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            // this.updateSelectedChartId = [...this.selectedChartIds];
            // this.chartSelections = Array(this.chartTypes.length).fill(null);
            // this.chartTypes.forEach((chart, index) => {
            //   this.chartSelections[index] = chart?.Name;
            // });
            this.isDataFetched = true;
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    fetchData() {
      this.showLoader = true;
      this.dashboardName = localStorage.getItem("dashboard");
      const dashboardString = JSON.stringify(this.dashboardName);
      api
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = dashboardData.Charts;
            // Always use the full chart type list
            // this.chartTypesName = [
            //   ...this.allChartTypes.map((chart) => chart.Name),
            //   "+ Create Plot"
            // ];
            // this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            // this.updateSelectedChartId = [...this.selectedChartIds];
            // this.chartSelections = Array(this.chartTypes.length).fill(null);
            // this.chartTypes.forEach((chart, index) => {
            //   this.chartSelections[index] = chart?.Name;
            // });
            this.isDataFetched = true;
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    deleteDashboard() {
      this.showDeleteConfirmation = true; // Show the confirmation alert
    },
    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false; // Hide the alert
    },
    confirmDeleteDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;
      const dashboardName = this.dashboard_name;
      var defaultDashboard = localStorage.getItem("defaultDashboard")

      this.loading = true;

      api
        .delete(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards/" +
          dashboardId,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.loading = false;
            this.showDeleteConfirmation = false;
            if (dashboardName === defaultDashboard) {
              localStorage.removeItem("defaultDashboard")
            }
            localStorage.removeItem("lastVisitedDashboard");
            localStorage.removeItem("dashboard")
            this.$emit("dashboard-delete");
            this.resetView();
          }
          else if (response && response.data && response.data.message) {
            console.error(response.data.message);
          }
          else {
            console.error("Error deleting dashboard");
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
          this.loading = false;
        });
    },
    resetView() {
      this.$emit('update:selectedDashboard', {});  // Ensure selected dashboard is cleared
      this.dashboard = "";
      this.dashboard_name = "";
      this.dashboard_id = "";
      this.dashboardAccessLevel = "";
      this.isPublic = false;
      this.chartTypes = [];
      this.isDataFetched = false;

      localStorage.removeItem("dashboard");
      localStorage.removeItem("lastVisitedDashboard");

      this.$forceUpdate();
      const getDefaultDashboard = localStorage.getItem("defaultDashboard");
    },
  },
  mounted() {
    const getDefaultDashboard = localStorage.getItem("defaultDashboard");
    if (getDefaultDashboard) {
      this.fetchDefaultData()
    }
    else {
      this.fetchData();
    }
    const dashboard = localStorage.getItem("dashboard")
    if (getDefaultDashboard === dashboard) {
      this.isDefault = true;
    }
    else {
      this.isDefault = false;
    }
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.chart-container {
  width: 100%;
  height: 400px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>