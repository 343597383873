<template>
  <div class="chart-selection-wrap pb-4 mt-4">
    <ScreenLoader v-if="showLoader == true"></ScreenLoader>
    <v-form v-else>
      <div class="dashbaord-top-wrap">
        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="showLoader == true">
            <ScreenLoader></ScreenLoader>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center justify-center" v-if="timeoutMessage">
            <div class=" timeout">
              <div class="text-center">
                {{ timeoutMessage }}
                <v-icon flat @click="getSavedDashboards()" color="blue-darken-3" icon="mdi-reload"
                  size="large"></v-icon>
              </div>
            </div>
          </v-col>
          <div
            v-else-if="showTitle || (dashboardAccessLevel == 'private' || (dashboardAccessLevel == 'public' && isAdmin))"
            class="title-edit-delete-div">
            <h6 class="main-heading text-capitalize">
              {{ dashboard }}
            </h6>
            <div class="pa-0 edit-delete-dashboard">
              <div v-if="isAdmin && showCancelBtn" class="switch-div dashboard-switch-div">
                <label for="">Access Level:</label>
                <v-switch v-model="isPublic" color="info" inset :label="dashboardAccessLevel"
                  @change="handleToggleChange" hide-details></v-switch>
              </div>
              <v-checkbox class="default-checkbox" v-if="showCancelBtn" label="Make Default" color="info"
                v-model="isDefault"></v-checkbox>
              <v-btn variant class="save-btn" v-if="showEditBtn && (dashboardAccessLevel !== 'public' || isAdmin)" @click="openEdit">
                <img :src="pencil" alt="edit" />
                <span style="margin-top: 2px">Edit</span>
              </v-btn>
              <v-btn v-if="showCancelBtn" variant class="save-btn" @click="closeEdit">
                <img :src="cancel" alt="cancel" />
                <span style="margin-top: 2px">Cancel</span>
              </v-btn>
              <DeleteDashboard :dashboard_id="dashboard_id" :dashboardAccessLevel="dashboardAccessLevel" :isAdmin="isAdmin" :dashboard_name="dashboard_name"
                @dashboard-delete="reloadDashboards" />
            </div>
          </div>
        </v-row>

        <dashboard-charts class="mt-2" v-if="showDashboardLoader"></dashboard-charts>
        <v-row no-gutters class="mb-3" v-else-if="editName">
          <v-col cols="12" class="pa-0 pb-2 pl-md-0 py-md-0">
            <label for="">Edit Dashboard Name</label>
            <v-text-field  variant="solo"
            class="custom-field" v-model="dashboard_name" required placeholder="Edit Name" hide-details
              outlined></v-text-field>
          </v-col>
        </v-row>
      </div>
      <ChartsData ref="childRef" v-if="isDataFetched" :hideExpand="hideExpand" :chartTypes="chartTypes"
        :chartSelections="chartSelections" :chartTypesName="chartTypesName" :showSelectboxes="showSelectboxes"
        @chart-type-changed="chartTypeChange" @update-selected-chart-ids="handleSelectedChartIdsUpdate"
        @update-show-studio-dialog="handleShowStudioDialog" />
      <div class="save-dash save-dashboards" v-if="showCharts == false">
        <v-btn block flat @click="openDialog()" class="save-btn text-none" :disabled="isUpdateDisabled">
          <img :src="saveDashboard" alt="update_dashboard" />
          <span>Save Dashboard Settings</span></v-btn>
      </div>
    </v-form>
    <v-dialog v-model="showStudioDataDialog" width="90vw" height="85vh">
      <v-card class="position-relative">
        <v-card-text>
          <div class="d-flex align-center justify-space-between pa-4 pb-0">
            <v-spacer></v-spacer>
            <v-btn @click="closeModal" flat icon class="chart-preview-close-btn" size="x-small">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <StudioData class="px-4" @save-chart="handleChartSavedStudio" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
    <ConfirmUpdateDashboard ref="childComponent" @open-dialog="openDialog" @dashboard-update="updateDashboard" />
  </div>
</template>

<script>
import axios from "axios";
import api from "../../../axiosInterceptor"
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import StudioData from '../FluidsDataStudioTwo/StudioData.vue';
import ChartsData from "./ChartsData.vue";
import DeleteDashboard from "./DeleteDashboard"
import DashboardCharts from '../Loaders/DashboardCharts.vue';
import ConfirmUpdateDashboard from "../Dashboard/ConfirmUpdateDashboard.vue"
import { mapGetters } from "vuex";

export default {
  name: "BoardSelected",
  components: {
    ScreenLoader,
    StudioData,
    ChartsData,
    DeleteDashboard,
    DashboardCharts,
    ConfirmUpdateDashboard
  },
  data() {
    return {
      showLoader: true,
      dashboardName: [],
      dashboardsArr: [],
      tenantId: localStorage.getItem("tenantId"),
      dashboard: null,
      chartTypes: [],
      chartTypesName: [],
      chart_one: null,
      chart_two: null,
      chart_three: null,
      chart_four: null,
      chart_five: null,
      chart_six: null,
      dashboard_id: "",
      newDashboard: [],
      showChartInput: false,
      showSaveBtn: false,
      showTitle: true,
      editName: false,
      dashboard_name: "",
      selectedChartIds: [],
      loading: false,
      isDataFetched: false,
      showCharts: true,
      showStudioDataDialog: false,
      showAlert: false,
      errorMessage: "",

      timeoutMessage: "",
      showDashboardLoader: true,
      showEditBtn: true,
      showCancelBtn: false,
      dashboardAccessLevel: "",
      pencil: require("../../../assets/Images/Dashboard/pencil.png"),
      cancel: require("../../../assets/Images/Dashboard/Delete.png"),
      add: require("../../../assets/Images/Navbar/white-add.png"),
      saveDashboard: require("../../../assets/Images/Dashboard/save.png"),
      isDefault: false,
      updateSelectedChartId: [],
      chartSelections: Array(6).fill(null),
      hideExpand: false,
      isPublic: false,
    };
  },
  computed: {
    ...mapGetters("tenantId", ["getUser"]),
    isAdmin() {
      return this.getUser && this.getUser.role === "Administrator";
    },
    isUpdateDisabled() {
      return (
        !this.dashboard_name ||
        !this.chartSelections.some(chart => chart) // Check if at least one chart is selected
      );
    },
  },
  methods: {
    handleToggleChange() {
      // Update access level based on the switch state
      this.dashboardAccessLevel = this.isPublic ? "public" : "private";
    },
    handleShowStudioDialog(updateShowStudioDialog) {
      this.showStudioDataDialog = updateShowStudioDialog;
    },
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
    openStudioDataDialog() {
      this.showStudioDataDialog = true;
    },
    closeModal() {
      this.showStudioDataDialog = false;
    },
    async handleChartSavedStudio(newChart) {
      await this.getSavedCharts();

      // Find the matching chart from allChartTypes
      const isMatch = this.allChartTypes.find(chart => chart.Name === newChart.name);

      if (isMatch) {
        // Find the first index in chartSelections where the value is null or undefined
        const indexToUpdate = this.chartSelections.findIndex(item => item === null || item === undefined);

        if (indexToUpdate !== -1) {
          // Update the chartSelections at the found index
          this.chartSelections[indexToUpdate] = isMatch.Name;
          // Update updateSelectedChartId with the new chart's ID
          this.updateSelectedChartId[indexToUpdate] = isMatch.ID;
          this.chartTypes.push(isMatch);
        }

        // Optionally, you can also add the chart type to chartTypes
      }

      this.closeModal();
    },


    async getSavedCharts() {
      this.showLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      await axios
        .get(process.env.VUE_APP_API_URL + "public/api/v1/charts", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
        })
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.allChartTypes = response.data.data.charts;
            this.chartTypesName = [
              ...this.allChartTypes.map((chart) => chart.Name),
              "+ Create Plot"
            ];
          }
          else if (response && response.data && response.data.message) {
            console.error(response.data.message);
          }
          else {
            console.error("Error in fetching charts");
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },

    chartTypeChange(selectedChartName, chartIndex) {
      // Handle "+ Create New Chart" logic early
      if (selectedChartName === "+ Create Plot") {
        this.openStudioDataDialog();

        // Reset the specific chart selection to null dynamically
        if (chartIndex >= 0 && chartIndex < 6) {
          this.chartSelections[chartIndex] = null;
        }

        // Exit early to avoid further processing
        return;
      }

      // Handle case when the selection is cleared
      if (!selectedChartName) {
        // Remove the ID for this chartIndex from selectedChartIds
        this.selectedChartIds.splice(chartIndex, 1);
        this.updateSelectedChartId = [...this.selectedChartIds];

        // Reset the dropdown value
        this.chartSelections[chartIndex] = null;

        // Clear the corresponding chart
        this.$refs.childRef.clearChart(chartIndex);


        // Emit the updated selectedChartIds to the parent
        this.$emit("update-selected-chart-ids", this.selectedChartIds);

        return; // Exit early
      }

      const selectedChart = this.allChartTypes.find(
        (chart) => chart.Name === selectedChartName
      );

      if (selectedChart) {
        this.updateSelectedChartIds[chartIndex] = selectedChart.ID;
      }

      if (!selectedChart) {
        console.error("Selected chart not found:", selectedChartName);
        return;
      }

      // Update selected chart ID for the given index
      this.selectedChartIds[chartIndex] = selectedChart.ID;
      // this.updateSelectedChartId = this.selectedChartIds[chartIndex]
      this.updateSelectedChartId = [...this.selectedChartIds];

      // Filter out the selected chart IDs and prepare available chart names
      const availableCharts = this.allChartTypes.filter(
        (chart) => !this.selectedChartIds.includes(chart.ID)
      );

      this.chartTypesName = availableCharts
        .map((chart) => chart.Name)
        .concat("+ Create Plot");

      // Emit updated selectedChartIds to the parent
      this.$emit("update-selected-chart-ids", this.selectedChartIds);
      this.$nextTick(() => {
        // Call the fetchChartsData method in the child component
        this.$refs.childRef.fetchChartsData(chartIndex, selectedChart);
      });
    },

    openEdit() {
      this.showChartInput = true;
      this.showSaveBtn = true;
      this.showTitle = false;
      this.editName = true;
      this.showCharts = false;
      this.showEditBtn = false;
      this.showCancelBtn = true;
      this.showSelectboxes = true;
      this.hideExpand = true;
      localStorage.setItem("editDashboard", true)
    },
    closeEdit() {
      this.hideExpand = false;
      this.showChartInput = false;
      this.showSaveBtn = false;
      this.showTitle = true;
      this.editName = false;
      this.showCharts = true;
      this.getSavedDashboards();
      var dashboardSelected = localStorage.getItem("dashboard")
      var defaultDashboard = localStorage.getItem("defaultDashboard")
      if (defaultDashboard === dashboardSelected) {
        this.fetchDefaultData()
      }
      else {
        this.fetchData();
      }
      this.showCancelBtn = false;
      this.showSelectboxes = false;
      localStorage.removeItem("editDashboard")
      this.showEditBtn = true
    },
    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards?.map(
              (dashboard) => dashboard.name
            );
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching Dashboards";
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },

    fetchData() {
      this.showLoader = true;
      this.dashboardName = localStorage.getItem("dashboard");
      const dashboardString = JSON.stringify(this.dashboardName);
      this.showDashboardLoader = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = dashboardData.Charts;
            // Always use the full chart type list
            this.chartTypesName = [
              ...this.allChartTypes.map((chart) => chart.Name),
              "+ Create Plot"
            ];
            this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            this.updateSelectedChartId = [...this.selectedChartIds];
            this.chartSelections = Array(this.chartTypes.length).fill(null);
            this.chartTypes.forEach((chart, index) => {
              this.chartSelections[index] = chart?.Name;
            });
            this.isDataFetched = true;
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showLoader = false;
          this.showDashboardLoader = false;
        });
    },

    fetchDefaultData() {
      this.showLoader = true;
      this.dashboardName = localStorage.getItem("defaultDashboard");
      const dashboardString = JSON.stringify(this.dashboardName);
      this.showDashboardLoader = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = dashboardData.Charts;
            this.chartTypesName = [
              ...this.allChartTypes.map((chart) => chart.Name),
              "+ Create Plot"
            ];
            this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            this.updateSelectedChartId = [...this.selectedChartIds];
            this.chartSelections = Array(this.chartTypes.length).fill(null);
            this.chartTypes.forEach((chart, index) => {
              this.chartSelections[index] = chart?.Name;
            });
            this.isDataFetched = true;
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showDashboardLoader = false;
          this.showLoader = false;
        });
    },

    fetchDataOnChange(newDashboardName) {
      this.showLoader = true;
      this.newDashboard = localStorage.getItem("dashboard");
      const dashboardString = JSON.stringify(this.newDashboard);
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            this.showEditBtn = true;
            this.showCancelBtn = false;
            this.showSelectboxes = false;
            localStorage.removeItem("editDashboard")
            this.dashboard = response.data.data.dashboards[0].name;
            this.dashboard_name = response.data.data.dashboards[0].name;
            this.dashboard_id = response.data.data.dashboards[0].ID;
            this.dashboardAccessLevel = response.data.data.dashboards[0].AccessLevel;
            this.isPublic = this.dashboardAccessLevel === "public";
            this.chartTypes = response.data.data.dashboards[0].Charts;
            this.chartTypesName = [
              ...this.allChartTypes.map((chart) => chart.Name),
              "+ Create Plot"
            ];
            this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            this.updateSelectedChartId = [...this.selectedChartIds];
            this.chartSelections = Array(this.chartTypes.length).fill(null);
            this.chartTypes.forEach((chart, index) => {
              this.chartSelections[index] = chart?.Name;
            });
            this.isDataFetched = true;
          }
          else if (response && response.data && response.data.message) {
            this.timeoutMessage = response.data.message;
          }
          else {
            this.timeoutMessage = "Error in fetching data";
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    changeDashboard() {
      const selectedDashboard = this.dashboardsArr.find(
        (dashboard) => dashboard.name === this.dashboard
      );

      if (selectedDashboard.name) {
        this.fetchDataOnChange(selectedDashboard.name);
      }
      const getDefaultDashboard = localStorage.getItem("defaultDashboard");
      const dashboard = localStorage.getItem("dashboard")
      if (getDefaultDashboard === dashboard) {
        this.isDefault = true;
      }
      else {
        this.isDefault = false;
      }
    },
    updateDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;
      this.loading = true;

      const sanitizedChartsID = Array.isArray(this.updateSelectedChartId)
        ? this.updateSelectedChartId.filter((id) => id)
        : [];


      const requestBody = {
        Data: {
          dashboard: {
            name: this.dashboard_name,
            chartsID: sanitizedChartsID,
            AccessLevel: this.dashboardAccessLevel,
            isDefault: this.isDefault,
          },
        },
      };

      axios
        .put(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + dashboardId,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response && response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
            if (this.dashboard_name) {
              localStorage.setItem("dashboard", this.dashboard_name)
            }
            if (this.isDefault) {
              localStorage.setItem("defaultDashboard", this.dashboard_name);
            }
            const getDashboard = localStorage.getItem("dashboard");
            const getDefault = localStorage.getItem("defaultDashboard");
            if (getDashboard === getDefault && this.isDefault === false) {
              localStorage.removeItem("defaultDashboard")
            }
            this.$emit("dashboard-name-update");
            this.getSavedDashboards();
            this.fetchData();
            this.loading = false;
            this.isDataFetched = true;
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            const updateDashboardName = this.dashboard_name.replace(/\s/g, "-");
            this.fetchDataOnChange(updateDashboardName);
          }
          else if (response && response.data && response.data.message) {
            console.error(response.data.message);
          }
          else {
            console.error("Error while updating dashboard");
          }
        })
        .catch((error) => {
          this.showAlert = true;
          this.loading = false;
        })
        .finally(() => {
          this.hideExpand = false;
        });
    },
    reloadDashboards() {
      this.$emit("dashboard-reload");
    },
  },
  async mounted() {
    this.getSavedDashboards();
    await this.getSavedCharts();
    const getDefaultDashboard = localStorage.getItem("defaultDashboard");
    if (getDefaultDashboard) {
      this.fetchDefaultData()
    }
    else {
      this.fetchData();
    }
    const dashboard = localStorage.getItem("dashboard")
    if (getDefaultDashboard === dashboard) {
      this.isDefault = true;
    }
    else {
      this.isDefault = false;
    }
  },
};
</script>